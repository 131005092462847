import CustomCheckBox from "../../../../../components/CustomCheckBoxComponent";
import styles from './VariationsCheckboxPanel.module.css';

export const VariationsCheboxPanel = ({ isVisible, setIsVisible }) => {
    return (
        <div className={styles.wrapper}>
            <span>Вариативный товар?</span>
            <div style={{ display: "flex", alignItems: "center" }}>
                <CustomCheckBox checked={isVisible} onChange={() => { setIsVisible(true) }} />
                <span style={{ alignSelf: "flex-end" }}>Да</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <CustomCheckBox checked={!isVisible} onChange={() => { setIsVisible(false) }} />
                <span>Нет</span>
            </div>
        </div>
    );
}