import React from "react";
import ReactPlayer from "react-player";
import styles from "./VideosComponent_v2.module.css";

class Videos extends React.Component {
    constructor(props) {
        super(props);
        this.state = { url: "https://youtu.be/5BmQbsqFehs", classActive: "" };
    }

    urlHandler = (e) => {
        let url = e.target.dataset.url;
        const container = e.target.parentElement.children;
        for (let el of container) {
            el.classList.remove(styles.videosContainerListItemActive);
        }
        e.target.classList.add(styles.videosContainerListItemActive);
        this.setState({ url });
    }

    render() {
        return (
            <section className={styles.videos}>
                <div className={styles.videosContainer}>
                    <div className={styles.videosContainerList}>
                        <span className={styles.videosContainerListTitle}>Посмотрите подробную видео инструкцию о том, как:</span>
                        <ul className={styles.videosContainerListItems}>
                            <li className={`${styles.videosContainerListItem} ${styles.videosContainerListItemActive}`} data-url={"https://youtu.be/5BmQbsqFehs"} onClick={this.urlHandler}>Как создать бота в телеграмм?</li>
                            <li className={styles.videosContainerListItem + this.state.classActive} data-url={"https://youtu.be/TbRxXDHc_kI"} onClick={this.urlHandler}>Как изменить внешний вид бота?</li>
                            <li className={styles.videosContainerListItem + this.state.classActive} data-url={"https://youtu.be/6ZiOZVxHJ2Q"} onClick={this.urlHandler}>Как создать магазин?</li>
                            <li className={styles.videosContainerListItem + this.state.classActive} data-url={"https://youtu.be/42yBN4qUmpU"} onClick={this.urlHandler}>Как добавить товары?</li>
                            <li className={styles.videosContainerListItem + this.state.classActive} data-url={"https://youtu.be/4e0l09obYhY"} onClick={this.urlHandler}>Как создать ссылку на магазин?</li>
                        </ul>
                    </div>
                    <div className={styles.videosContainerListPlayer}>
                        <ReactPlayer url={this.state.url} width="100%" height="100%" controls />
                    </div>

                </div>
            </section>
        )
    }
}
export default Videos;