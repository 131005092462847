import React, { useEffect, useState } from "react";
import FormatNumbers from "../../../components/FormatNumbersComponent";
import ImageComponent from "../../../components/ImageCustomComponet";
import styles from "./OrderItemInfoComponent.module.css";
import api from "../../../api/api";


function OrderItemInfoComponent({ position }) {
    const [imgPath, setImgPath] = useState('');

    api.getOrderInfoImg(position.nm_id).then(r => {
        if (r.status === 200) {
            setImgPath(r.data.url);
        }
    }).catch(error => { console.log(error) });

    return (
        <div className={styles.item}>
            <ImageComponent imagePath={imgPath} className={styles.img} ourWidth={80} />
            <div className={styles.info}>
                <span>{position.name}</span>
                <div className={styles.option}>
                    <span>{position.var1_name}</span>
                    <span>{position.var1}</span>
                </div>
                <div className={styles.option}>
                    <span>{position.var2_name}</span>
                    <span>{position.var2}</span>
                </div>
            </div>
            <div className={styles.status}>
                Оформлен
            </div>
            <div className={styles.count}>
                {position.quantity}
            </div>
            <div className={styles.price}>
                <FormatNumbers number={position.price / 100 + " ₽"} styles={{ textDecoration: "line-through", color: "gray" }} />
                <FormatNumbers number={position.total_price / 100 + " ₽"} />
            </div>
            <div className={styles.sale}>
                <FormatNumbers number={(position.price - position.total_price) / 100 + " ₽"} />
            </div>
            <div className={styles.sum}>
                <FormatNumbers number={position.total_price / 100 * position.quantity + " ₽"} />
            </div>
        </div>
    )
}
export default OrderItemInfoComponent;