import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import api from '../api/api';
import activeSort from "../assets/iconizer-sort.svg";
import sort from "../assets/sort.png";
import Loading from '../components/Loading';
import TariffModal from '../pages/ProfilePage/components/TariffModal';
import styles from "../styles/ProductsComponent_v2.module.css";
import { error, success } from "./notification";
import Pagination from './Pagination';
import PopupCustomComponent from './PopupCustomComponent';
import ProductItem from './ProductListComponent_v2';
import ProductsMobileItemComponent from './ProductsMobileComponent';


function Products({ products, setProducts, handleDelete, setIsInfo, setInfo, tariff, handleSort, selectedSort, totalPages, currentPage, setCurrentPage, setTariffStatus }) {

    const nav = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const fileInputRef = useRef();
    const [menuState, setMenuState] = useState([]);
    const [pricing, setPricing] = useState({ 1: 0, 2: 0 });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({ type: 1, price: 1999, old_price: 1999, name: 'Стандарт' });

    const handlerDeleteAllChoosenProducts = () => {
        menuState.forEach(el => {
            api.deleteProductV2(el).then(() => { setTariffStatus(true); }).catch((errors) => { console.log(errors) })
        });
        const updatedPositions = products.filter((position) => !menuState.includes(position.id));
        setProducts(updatedPositions);
        setMenuState([]);
    };

    const handlePin = (id) => {
        api.pinProduct(id).then(() => {
            setProducts((prevProducts) =>
                prevProducts.map((product) =>
                    product.id === id
                        ? { ...product, pin: !product.pin }
                        : product
                )
            );
        }).catch((errors) => { console.log(errors) });
    };

    const handlerPinAllChoosenProducts = () => {
        menuState.forEach(el => {
            handlePin(el);
        });
        setMenuState([]);
        success('Товары закреплены');
    };

    const handleCheckboxChange = useCallback((id) => {
        setMenuState((prevCheckedBoxes) => {
            const updatedCheckedBoxes = [...prevCheckedBoxes];
            const index = updatedCheckedBoxes.indexOf(id);

            if (index !== -1) {
                updatedCheckedBoxes.splice(index, 1);
            } else {
                updatedCheckedBoxes.push(id);
            }

            return updatedCheckedBoxes;
        });
    }, []);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = async (e) => {
        setIsLoading(true);
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            try {
                const storeId = localStorage.getItem('current_store_id');
                if (!storeId) {
                    console.error('store_id not found in localStorage');
                    return;
                }
                const formData = new FormData();
                formData.append('file', selectedFile);
                formData.append('store_id', storeId);
                api.loadProductsFromFileV2(formData).then((r) => { if (r?.status) { setIsLoading(false); window.location.reload(); } else { setIsLoading(false); error(r.response.data.details) } });

            } catch (e) { error('Произошла ошибка...'); }
        }
    };

    const handleStandartChoice = () => {
        setModalContent(prev => ({ ...prev, type: 1, price: pricing.standart.price / 100, old_price: pricing.standart.old_price / 100, name: 'Стандарт' }));
        setIsModalOpen(true);
    };

    const handlerToAddProduct = () => {
        nav('/product/new');
    }

    useEffect(() => {
        console.log('DEBUG: HERE');
        api.getPricing().then(r => { setPricing(r.data) });
        document.body.classList.add(styles.bodyRuleDisappear);
        return () => {
            document.body.classList.remove(styles.bodyRuleDisappear);
        };
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.products}>
                <h1 className={styles.productsTitle}>заполнение магазина</h1>
                <div className={styles.productsContainer}>
                    {tariff ?
                        <button onClick={handlerToAddProduct} className={styles.productsAdd}>
                            <span>+</span>
                            <span> добавить новый товар *</span>
                        </button> : null}
                    <div className={styles.productsInfo}>
                        <div>
                            <span>Статус</span>
                            <img src={selectedSort === "state" ? activeSort : sort} alt="" onClick={() => handleSort("state")} />
                        </div>
                        <div>
                            <span>Фото</span>
                        </div>
                        <div>
                            <span>Наименование</span>
                            <img src={selectedSort === "name" ? activeSort : sort} alt="" onClick={() => handleSort("name")} />
                        </div>
                        <div>
                            <span>Цена до скидки</span>
                            <img src={selectedSort === "price" ? activeSort : sort} alt="" onClick={() => handleSort("price")} />
                        </div>
                        <div>
                            <span>Цена со скидкой</span>
                            <img src={selectedSort === "salePrice" ? activeSort : sort} alt="" onClick={() => handleSort("salePrice")} />
                        </div>
                        <div>
                            <span>Категория</span>
                            <img src={selectedSort === "category" ? activeSort : sort} alt="" onClick={() => handleSort("category")} />
                        </div>
                        <div>
                            <span>Остаток</span>
                            <img src={selectedSort === "stock" ? activeSort : sort} alt="" onClick={() => handleSort("stock")} />
                        </div>
                    </div>
                    <div className={styles.productsStorage}>
                        {products.length === 0 ?
                            <div className={styles.emptyMessage}>
                                {tariff ?
                                    <div className={styles.addButtons}>
                                        <button onClick={handlerToAddProduct}>Добавить товар</button>
                                        <input
                                            type="file"
                                            accept=".xlsx"
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                        />
                                        <button onClick={handleButtonClick}>Загрузить из файла***</button>
                                    </div>
                                    : null}

                            </div> : <>
                                {menuState.length > 0 ? <div className={styles.productMenu}>
                                    <p>Выбрано товаров: {menuState.length}</p>
                                    <span onClick={handlerPinAllChoosenProducts}>Закрепить</span>
                                    <PopupCustomComponent triggerEl={<span>Удалить</span>} title={"Вы действительно хотите удалить выбранные товары?"} text={"После удаления восстановить товары не получится"} handler={handlerDeleteAllChoosenProducts} />
                                </div> : null}
                                {products.map(item => <ProductItem key={uuid()} item={item} handleDelete={handleDelete} handlerForChoosenProduct={handleCheckboxChange} setIsInfo={setIsInfo} setInfo={setInfo} menuArr={menuState} />)}
                            </>

                        }
                    </div>
                    {tariff ?
                        (<div className={styles.addButtons}>
                            {products.length === 0 ? null : <>
                                <button className={styles.addBtn} onClick={handlerToAddProduct}>Добавить товар</button>
                                <input
                                    type="file"
                                    accept=".xlsx"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    ref={fileInputRef}
                                />
                                <button className={styles.addFileBtn} onClick={handleButtonClick}>Загрузить из файла***</button>
                            </>}
                        </div>) : products.length === 4 ? <button className={styles.addFileBtn} onClick={handleStandartChoice}>Повысить тариф</button> : null}

                </div>
                <div className={styles.productsMobileContainer}>
                    {products.length === 0 ?
                        <div className={styles.emptyMessage}>
                            <span>Вы пока не добавили ни один товар</span>
                            {tariff ?
                                <div className={styles.addMobileCont}>
                                    <div className={styles.addButtonsMobile}>
                                        <button onClick={handlerToAddProduct} className={styles.addBtn}>Добавить товар</button>
                                    </div>
                                </div>
                                : null}

                        </div> :
                        products.map(item => <ProductsMobileItemComponent key={uuid()} item={item} handleDelete={handleDelete} setIsInfo={setIsInfo} setInfo={setInfo} />)
                    }
                    {tariff ?
                        (<div className={styles.addButtonsMobile}>
                            {products.length === 0 ? null : <button className={styles.addBtn} onClick={handlerToAddProduct}>Добавить новый товар</button>}
                        </div>)
                        : products.length === 4 ? <button className={styles.addBtn} onClick={handleStandartChoice}>Повысить тариф</button> : null
                    }
                </div>
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={setCurrentPage} />
            </div>
            <span className={styles.infoText}>* Со списком запрещенных к продаже товаров вы можете ознакомиться в правилах использования сервиса;</span>
            <span className={styles.infoText}>** Количество заказов / количество добавлений в корзину.</span>
            <span className={styles.infoText}>*** Чтобы загрузить товары из файла - скачайте и заполните наш <a href="/files/template.xlsx" download={"Шаблон для загрузки товаров Botique.xlsx"}>шаблон</a>.</span>
            <TariffModal isOpen={isModalOpen} content={modalContent} onClose={() => { setIsModalOpen(false); }} />
            {isLoading ? <Loading /> : null}
        </div>
    )
}
Products.defaultProps = {
    products: []
}
export default Products;