import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import CustomCheckBox from '../../../../../components/CustomCheckBoxComponent';
import DropdownForForm from '../../../../../components/DropDownForFormComponent';
import PopupCustomComponent from '../../../../../components/PopupCustomComponent';
import styles from './CharacteristicItemsList.module.css';

export const CharacteristicItemsList = ({ productData, setProductData, name, setSelectedVariants, handleDelete, setVariativeCharaceristics, characteristicOptions, variations, title }) => {

    const [chekedVariations, setCheckedVariations] = useState(
        variations ? variations.map((variation) => ({ variation: variation, isChecked: false, id: uuid() })) : []
    );

    const [newVariationValue, setNewVariationValue] = useState('');

    useEffect(() => {
        setCheckedVariations(variations ? variations.map((variation) => ({ variation: variation, isChecked: false, id: uuid() })) : []);
    }, [variations, characteristicOptions, productData.combos]);

    useEffect(() => {
        setSelectedVariants(chekedVariations.filter(item => item.isChecked).map(item => item.variation));
    }, [chekedVariations]);

    const handleCharacteristicOptionChange = (value) => {
        setProductData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setVariativeCharaceristics(prevState => ({
            ...prevState,
            [name]: { id: value, name: characteristicOptions.find(item => item.id === value), variants: [] }
        }))
    }

    const handleNewVariationChange = ({ target }) => {
        setNewVariationValue(target.value);
    }

    const handleAddNewVariant = () => {
        if (newVariationValue !== '') {
            setVariativeCharaceristics(prevState => ({
                ...prevState,
                [name]: { variants: Array.from(new Set([...variations, newVariationValue])) }
            }))
            setNewVariationValue('');
        }
    }

    const handleDeleteVariant = (variation) => {
        handleDelete(name, variation);
    }

    return (
        <div className={styles.wrapper}>
            <span className={styles.title}>{title}</span>
            <DropdownForForm
                options={characteristicOptions}
                placeHolder="Выберите из списка"
                isAllowed={() => true}
                setChoice={handleCharacteristicOptionChange}
                initialChoice={productData[name]}
            />
            <div className={styles.inputContainer}>
                <input
                    type="text"
                    placeholder="Введите значение"
                    value={newVariationValue}
                    onChange={handleNewVariationChange}
                    onKeyUp={({ key }) => {
                        if (key === 'Enter') {
                            handleAddNewVariant();
                        }
                    }}
                />
                <button type="button" onClick={handleAddNewVariant} />
            </div>
            <div className={styles.list}>
                {chekedVariations.map((el) => {
                    return (
                        <div key={el.id} className={styles.listItem}>
                            <CustomCheckBox
                                outId={`charc${el.id}`}
                                checked={el.isChecked}
                                onChange={() => {
                                    const newCheckedVariations = [...chekedVariations];
                                    newCheckedVariations[newCheckedVariations.findIndex(item => item.id === el.id)].isChecked = !el.isChecked;
                                    setCheckedVariations(newCheckedVariations);
                                }}
                            />
                            <span>
                                <label htmlFor={`charc${el.id}`}>
                                    {el.variation}
                                </label>
                            </span>
                            <PopupCustomComponent key={uuid()} triggerEl={<button type="button" />} title={"Вы действительно хотите удалить комбинации?"} text={"После удаления восстановить комбинации не получится"} handler={() => handleDeleteVariant(el.variation)} />
                        </div>
                    )
                })}
            </div>
        </div>
    );
}