import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from '../api/api';
import logo from "../assets/svg_vector_1.png";
import styles from "../styles/HeaderComponent_v2.module.css";
import Dropdown from "./DropDownComponent_v2";
import BurgerMenu from "./MobileMenuComponent";
import ProfileDropdown from "./ProfileDropDownComponent_v2";

function Header({ title }) {
    const [storeList, setStoreList] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [tariff, setTariff] = useState({ allow_stores: true, subscription_status: false, design_choice: false, marketing: false });

    useEffect(() => {
        document.title = title;
        api.getStoresList()
            .then((response) => {
                if (response.response) { if (response.response.data.code === "user_not_found") { localStorage.clear(); } }

                var storesData = response.data.stores.map((store) => { return { name: store.name, id: store.id } });

                setStoreList(storesData);
                setTariff(response.data.tariff);

                var selectedStore;

                if (localStorage.getItem('current_store_id')) {
                    selectedStore = storesData.find(elem => elem.id === localStorage.getItem('current_store_id'));
                } else if (storesData.length > 0) {
                    selectedStore = storesData[0];
                    localStorage.setItem('current_store_id', selectedStore.id)
                }

                if (selectedStore) {
                    setSelectedOption(selectedStore.name);
                    api.getStoreSign(selectedStore.id).then(r => {
                        if (r.data) {
                            localStorage.setItem('_signiture_for_store_data', r.data.signiture);
                        }
                    });
                }
            })
            .catch(error => { console.log(error) });
    }, [title]);

    return (
        <header className={styles.header}>
            <div className={styles.headerRow}>
                <img className={styles.headerRowLogo} src={logo} alt="logo"></img>
                <div className={styles.headerRowInfo}>
                    <span>Мой магазин:</span>
                    <div className={styles.dropContainer}>
                        <Dropdown options={storeList} selectedOption={selectedOption} text={"Выбрать"} tariff={tariff} />
                    </div>
                    <ProfileDropdown />
                    <BurgerMenu options={storeList} selectedOption={selectedOption} tariff={tariff} activePartMenu={title} />
                </div>
            </div>
            <div className={styles.headerRow}>
                <ul>
                    <li style={title === "Главная" ? { textDecoration: "underline", color: "white" } : null}><Link to={"/"}>Главная</Link></li>
                    <li style={title === "Мои товары" ? { textDecoration: "underline", color: "white" } : null}><Link to={"/positions"}>Товары</Link></li>
                    {tariff.subscription_status && tariff.marketing && <li style={title === "Управление группами товаров" ? { textDecoration: "underline", color: "white" } : null}><Link to={"/product-groups"}>Группы товаров</Link></li>}
                    <li style={title === "Мои баннеры" ? { textDecoration: "underline", color: "white" } : null}><Link to={"/banners"}>Баннеры и акции</Link></li>
                    <li style={title === "Страница заказов" ? { textDecoration: "underline", color: "white" } : null}><Link to={"/orders"}>Заказы</Link></li>
                    {tariff.subscription_status && tariff.marketing && <li style={title === "Клиенты и рассылки" ? { textDecoration: "underline", color: "white" } : null}><Link to={"/marketing"}>Клиенты и рассылки</Link></li>}
                    <li style={title === "Поддержка" ? { textDecoration: "underline", color: "white" } : null}><Link to={"/support"}>Поддержка</Link></li>
                </ul>
            </div>
        </header>
    )
}
export default Header;