import { useState } from 'react';
import styles from './VariationBlock.module.css';
import { VariationForm } from './VariationForm/VariationFrom';
import { VariationsCardList } from './VariationsCardsList/VariationsCardsList';

export const VariationsBlock = ({ productData, setProductData, handleProductDataFieldChange, formErrors, isDigital }) => {

    const [choosenCombination, setChoosenCombination] = useState(0);

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                ИНФОРМАЦИЯ ПО ВАРИАЦИЯМ
            </div>
            <VariationsCardList
                combinations={productData.combos}
                choosenCombination={choosenCombination}
                setChoosenCombination={setChoosenCombination}
                productData={productData}
                setProductData={setProductData}
            />
            {Boolean(productData.combos.length) && <VariationForm
                combination={productData.combos[choosenCombination]}
                handleProductDataFieldChange={handleProductDataFieldChange}
                comboIndex={choosenCombination}
                productDataState={productData.state}
                setProductData={setProductData}
                productData={productData}
                formErrors={formErrors}
                isDigital={isDigital}
            />}
        </div>
    );
}