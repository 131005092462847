import React, { useEffect, useRef, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../api/api';
import logo from "../../assets/logo.svg";
import ErrorMessage from '../../components/ErrorMessage';
import Loading from '../../components/Loading';
import styles from './LoginPage.module.css';

const LoginPage = () => {
    const nav = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [errorText, setErrorText] = useState('');
    const [isError, setIsError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const inputTarget = useRef(null);

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsLoading(true);

        api.getAccessToken({ username: username, password: password }).then((response) => {
            setIsLoading(false);

            if (response.code !== "ERR_BAD_REQUEST") {
                setIsError(false);
                const data = response.data;
                localStorage.clear();
                localStorage.setItem('access_token', data.access);
                localStorage.setItem('refresh_token', data.refresh);
                localStorage.setItem('userID', data.user_id);
                localStorage.setItem('hash', data.hash);
                nav('/');
            } else {
                const data = response.response.data;
                if (data.detail === 'Incorrect password.') {
                    if (data.count < 2) {
                        setErrorText(`Вы ввели неверный пароль. Проверьте данные и попробуйте снова.`)
                        setIsError(true);
                    } else {
                        setErrorText(`Вы ввели неверный пароль. Осталось ${7 - data.count} попыток.`)
                        setIsError(true);
                    }
                } else if (data.detail === "Account is not found.") {
                    setErrorText(`Неверные данные. Попробуйте снова.`)
                    setIsError(true);
                } else if (data.detail === 'Email is not confirmed.') {
                    setErrorText(`Подтвердите адрес электронной почты. `)
                    setIsError(true);
                } else if (data.detail === 'Failed. Account temporarily blocked. Please try again later.') {
                    setErrorText(`Аккаунт заблокирован на 5 минут. Вы слишком много раз ввели неверный пароль.`)
                    setIsError(true);
                } else if (data.detail === 'Account is temporarily blocked. Please try again later.') {
                    const timeLeft = new Date(new Date(data.until) - new Date());
                    if (timeLeft.getUTCMinutes() > 1) {
                        setErrorText(`Аккаунт временно заблокирован. Попробуйте снова через ${timeLeft.getUTCMinutes()} минуту`);
                    } else {
                        setErrorText(`Аккаунт временно заблокирован. Попробуйте снова через ${timeLeft.getUTCSeconds()} секунд`);
                    }
                    setIsError(true);
                } else if (data.detail === 'Banned.') {
                    setErrorText(`Ваш аккаунт заблокирован на время проверки соблюдения правил использования сервиса.`);
                    setIsError(true);
                }
            }
        })
            .catch(error => {
                setIsLoading(false);
                setErrorText("Проверьте правильность введенных данных или подтвердите адрес электронной почты, если вы этого еще не сделали, и попробуйте снова.");
                setIsError(!isError);
            });
    };

    useEffect(() => {
        document.title = "Авторизация"
        inputTarget.current.focus();
        document.body.classList.add(styles.bodyBlock);

        // eslint-disable-next-line no-undef
        YaAuthSuggest.init({
            client_id: '2c823a78f075493bb95d406b6f27b43f',
            response_type: 'token',
            redirect_uri: 'https://admin.thebotique.ru/suggest/token'
        }, 'https://admin.thebotique.ru/')
            .then(({ handler }) => handler())
            .then((data) => {
                setIsLoading(true);
                api.getAccessTokenForYandexToken({ 'token': data.access_token }).then(r => {
                    setIsLoading(false);
                    if (r.code !== "ERR_BAD_REQUEST") {
                        setIsError(false);
                        const data = r.data;
                        localStorage.clear();
                        localStorage.setItem('access_token', data.access);
                        localStorage.setItem('refresh_token', data.refresh);
                        localStorage.setItem('userID', data.user_id);
                        localStorage.setItem('hash', data.hash);
                        nav('/');
                    } else {
                        const data = r.response.data;
                        if (data.detail === 'Banned.') {
                            setErrorText('Ваш аккаунт заблокирован на время проверки соблюдения правил использования сервиса.');
                            setIsError(true);
                        } else {
                            setErrorText('Что-то пошло не так, попробуйте другой способ авторизации.');
                            setIsError(true);
                        }
                    }
                });

            })
            .catch(error => console.log('Обработка ошибки', error));

        return () => { document.body.classList.remove(styles.bodyBlock); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.loginPagePreview}>
                <div className={styles.loginPagePreviewHeader}>
                    <h4>Добро пожаловать на платформу BOTIQUE!</h4>
                    <p>Botique - это веб-сервис для создания “умных” телеграмм магазинов с готовым сервисом для проведения онлайн-оплаты.</p>
                </div>
                <div className={styles.loginPagePreviewFooter}>
                    <img src={logo} alt=''></img>
                    <span>2023-{new Date().getFullYear()} © Все права защищены</span>
                    <Link to={'https://docs.thebotique.ru/license-agreement'} target="_blank">Лицензионный договор</Link>
                    <Link to={'https://docs.thebotique.ru/terms-of-use'} target="_blank">Правила использования cервиса</Link>
                    <Link to={'https://docs.thebotique.ru/privacy-policy'} target="_blank">Политика обработки персональных данных</Link>
                </div>
            </div>
            <div className={styles.loginPageContainer}>
                <div className={styles.loginPage}>
                    <h4>Вход в систему</h4>
                    <form onSubmit={handleSubmit} >
                        <div className={styles.formGroup}>
                            <input ref={inputTarget} type="text" placeholder='Логин или Email' required onChange={e => setUsername(e.target.value)} className={styles.formInput} />
                        </div>

                        <div className={styles.formGroup}>
                            <input type={showPassword ? 'text' : 'password'} placeholder='Введите пароль' required onChange={e => setPassword(e.target.value)} className={styles.formInput} />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className={styles.passwordToggleButton}
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>


                        {isError && <div className={styles.buttonGroup}><ErrorMessage message={errorText} /></div>}

                        <div className={styles.buttonGroup}>
                            <button className={styles.loginButton} >Войти</button>
                        </div>
                    </form>

                    <div className={styles.fastLinks}>
                        <p className={styles.registerLink}>Нет аккаунта? <Link to={'/register'}>Зарегистрироваться</Link></p>
                        <p className={styles.registerLink}>Забыли пароль? <Link to={'/reset-password'}>Восстановить пароль</Link></p>
                    </div>
                </div>
            </div>
            {isLoading ? <Loading /> : null}
        </div >
    );
};

export default LoginPage;

