import React from 'react';

const NumberFormatter = ({ number, styles }) => {
    if (number === undefined) {
        return <span>0</span>
    }
    let formattedNumber = number.toLocaleString('en-US').replace(/(\d)(?=(\d{3})+\b)/g, '$1 ');
    formattedNumber.replace(',', '\u00A0');
    const parts = formattedNumber.split('/');
    const beforeDivider = parts[0] && parts[0].trim();
    const afterDivider = parts[1] && parts[1].trim();

    return (
        <span>
            {beforeDivider && <span style={{ marginRight: '3px', ...styles }}>{beforeDivider}</span>}
            {afterDivider && (
                <>
                    {beforeDivider && ' / '}
                    <span style={{ marginLeft: '3px' }}>{afterDivider}</span>
                </>
            )}
        </span>
    );
};

export default NumberFormatter;