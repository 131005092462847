import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api/api';
import Footer from '../../components/FooterComponent_v2';
import Header from '../../components/HeaderComponent_v2';
import NotificationComponent from '../../components/NotificationComponent';
import OrderInformationComponent from './components/OrderInformationComponent';

function OrderViewPage() {
    const [order, setOrder] = useState({});
    const { orderID } = useParams();
    const [orderReview, setOrderReview] = useState(null);
    const [notificationText] = useState('просто много текста текст есть текст');
    const [notificationState] = useState(false);

    useEffect(() => {
        api.getOrderv2(orderID).then((response) => {
            setOrder(response.data);
            api.getOrderReview(response.data.order_id).then(r => { if (r?.data) { setOrderReview(r.data); } });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderID]);


    return (
        <div>
            <Header title={"Страница заказов"} />
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", alignItems: "center" }}>
                <OrderInformationComponent order={order} orderReview={orderReview} />
            </div>
            {notificationState ? <NotificationComponent text={notificationText} /> : null}
            <Footer />
        </div>
    );
}

export default OrderViewPage;
