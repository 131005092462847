import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../api/api";
// import ani1 from "../../../assets/ani1.png";
// import ani2 from "../../../assets/ani2.png";
// import ani3 from "../../../assets/ani3.png";

import box1 from "../../../assets/template_1_cat.png";
import box2 from "../../../assets/template_1_prod.png";
import box3 from "../../../assets/template_1_order.png";

import list1 from "../../../assets/template_2_cat.png";
import list2 from "../../../assets/template_2_cart.png";
import list3 from "../../../assets/template_2_order.png";

// import balet1 from "../../../assets/balet1.png";
// import balet2 from "../../../assets/balet2.png";
// import balet3 from "../../../assets/balet3.png";
import base1 from "../../../assets/base_teamplate1.jpg";
import base2 from "../../../assets/base_teamplate2.jpg";
import base3 from "../../../assets/base_teamplate3.jpg";
// import clothe3 from "../../../assets/одежда1.png";
// import clothe2 from "../../../assets/одежда2.png";
// import clothe1 from "../../../assets/одежда3.png";
// import flow1 from "../../../assets/цветы1.png";
// import flow2 from "../../../assets/цветы2.png";
// import flow3 from "../../../assets/цветы3.png";
import styles from "./DesignComponent_v2.module.css";
import DesignItem from "./DesignItemComponent_v2";

function Design() {
    const { storeID } = useParams();
    const nav = useNavigate();

    // let initialData = [{ name: "base", images: [base1, base2, base3], active: false }, { name: "clothe", images: [clothe1, clothe2, clothe3], active: false }, { name: "balet", images: [balet1, balet2, balet3], active: false }, { name: "flowers", images: [flow1, flow2, flow3], active: false }, { name: "animals", images: [ani1, ani2, ani3], active: false }];
    let initialData = [{ name: "base", images: [base1, base2, base3], active: false }, { name: "box", images: [box1, box2, box3], active: false }, { name: "list", images: [list1, list2, list3], active: false }];
    const [data, setData] = useState(initialData);

    const [btnStatus, setBtnStatus] = useState(styles.activeBtn);

    const handlerForDesign = () => {
        setBtnStatus(styles.activeBtn);
    };

    const handleNext = () => {
        if (storeID === undefined) {
            nav('/store/new');
        } else {
            api.updateStoreTemplateCode(storeID, { template_code: localStorage.getItem('template_code') })
                .then((r) => {
                    nav(`/store/${storeID}`);
                })
        }
    };

    useEffect(() => {
        if (storeID === undefined) {
            localStorage.setItem('template_code', 0);

            setData(prevItems => {
                const updatedItems = [...prevItems];
                updatedItems[0] = { ...updatedItems[0], active: true };
                return updatedItems;
            });
        } else {
            const template_code = localStorage.getItem('template_code');
            setData(prevItems => {
                const updatedItems = [...prevItems];
                updatedItems[template_code] = { ...updatedItems[template_code], active: true };
                return updatedItems;
            });
        }
    }, [storeID]);
    useEffect(() => {
        document.body.classList.add(styles.bodyRuleDisappear);
    
        return () => {
          document.body.classList.remove(styles.bodyRuleDisappear);
        };
      }, []);
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Оформление магазина</h1>
            <span className={styles.text}>Выберите любой понравившийся вам шаблон оформления магазина <span>исходя из вашей специфики работы</span></span>
            <div className={styles.designContainer}>
                <ul className={styles.designContainerMenu}>
                    <li>Продажа товаров</li>
                    {/* <li>Предоставление услуг</li>
                    <li>Бронирование билетов</li> */}
                </ul>
                <div className={styles.design}>
                    {data.map((item, index) => <DesignItem images={item.images} handlerTochangeBtn={handlerForDesign} isActive={item.active} index={index} />)}
                </div>
            </div>
            <button className={btnStatus} onClick={handleNext}>Далее</button>
            <div className={styles.info}>
                <p>
                    Хотите индивидуальный дизайн? Можем предоставить вам услугу по разработке дизайна, разработанного специально под ваш магазин. Оставьте заявку и мы расскажем подробнее
                </p>
                <button onClick={() => { window.location = '/support' }}>Узнать подробнее</button>
            </div>
        </div>
    )
}
export default Design;