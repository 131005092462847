import moment from "moment";
import React, { useState } from "react";
import styles from "./MarketingHistoryComponent.module.css";

function MarketingHistoryComponent({ data }) {

    const [flag, setFlag] = useState(false);

    var imgUrl = null;

    if (data.photo) {
        const byteCharacters = atob(data.photo);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/jpeg' });
        imgUrl = URL.createObjectURL(blob);
    }

    const handlerBtnOpen = (e) => {
        let item = e.currentTarget;
        let btn = [...e.currentTarget.children][1];

        if (item.classList.contains(styles.itemActive)) {
            item.classList.remove(styles.itemActive);
            btn.classList.remove(styles.itemBtnActive);
            setFlag(false);
        } else {
            for (let el of e.currentTarget.parentElement.children) {
                el.classList.remove(styles.itemActive);
                let Btn = [...el.children][1];
                Btn.classList.remove(styles.itemBtnActive);
            }

            btn.classList.add(styles.itemBtnActive);
            item.classList.add(styles.itemActive);
            setFlag(true);
        }
    }

    return (
        <div className={styles.containerItem}>
            <div className={styles.item} onClick={handlerBtnOpen}>
                <div className={styles.itemText}>
                    <span>
                        {moment(data.created_at).format('HH:mm DD.MM.YYYY')}
                    </span>
                </div>
                <button className={styles.itemBtn}></button>
            </div>
            {flag ? <div className={styles.dataContainer}>
                {imgUrl !== null && <img src={imgUrl} alt="" />}
                <div className={styles.textContainer}>
                    <span>Текст рассылки</span>
                    <textarea placeholder={data.text} readOnly />
                </div>
                <div className={styles.userContainer}>
                    <span>Пользователей: {data.users?.length}</span>
                    <div className={styles.userList}>
                        {data.users.map((user, ind) => <span>{ind + 1}. {user.username}</span>)}
                    </div>
                </div>

            </div> : null}

        </div>

    )
}
MarketingHistoryComponent.defaultProps = {
    date: "1.01.2023",
    data: { img: "../../../assets/2StoreBack.png", text: "efewfefewfewfwef", users: ["Перепелкин Евгений", "Перепелкин Евгений", "Перепелкин Евгений", "Перепелкин Евгений"] }
};
export default MarketingHistoryComponent;
