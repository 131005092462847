import React from "react";
import { useNavigate } from "react-router-dom";
import PopupCustomComponent from "../../../components/PopupCustomComponent";
import styles from "./BannerItemComponent_v2.module.css";

function BannerItemComponent({ isImage, textOrImage, text, name, status, start, end, id, handlerDelete }) {
    const navigator = useNavigate();

    const handleBannerView = () => {
        navigator(`/banner/${id}/`)
    }

    const monthNames = ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"];

    return (
        <div className={styles.item}>
            <div className={styles.imgOrText}>
                {isImage ? <img src={textOrImage} alt="" /> : (<div className={styles.textAndImg}>
                    <img src={textOrImage} alt="" />
                    <span>{text}</span>
                </div>)}
            </div>
            <div className={styles.info}>
                <h5 className={styles.title}>{name}</h5>
                <span>{status ? `Статус: Выставлен` : `Статус: Скрыт`}</span>
                <span>Начало: {`${new Date(start).getDate()}-${monthNames[new Date(start).getMonth()]}-${new Date(start).getFullYear()}`}</span>
                <span>Завершение: {`${new Date(end).getDate()}-${monthNames[new Date(end).getMonth()]}-${new Date(end).getFullYear()}`}</span>
                <div className={styles.btns}>
                    <button className={styles.look} onClick={handleBannerView}>
                        Посмотреть
                    </button>
                    <PopupCustomComponent triggerEl={<button className={styles.delete}>
                        Удалить
                    </button>} title={"Вы действительно хотите удалить баннер/акцию?"} text={"После удаления восстановить баннер/акцию не получится"} handler={handlerDelete} paramsHandler={id}>
                    </PopupCustomComponent>
                </div>
            </div>
        </div >
    )
}
BannerItemComponent.defaultProps = {
    imgOrText: "text",
    name: "Name",
    status: "Active",
    start: "16 сентября 2023",
    end: "20 сентября 2023"
}
export default BannerItemComponent;