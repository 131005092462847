import React from 'react';
import styles from './ProductsChoiceModal.module.css';

const ProductsChoiceModal = ({ isOpen, onClose, onContinue, products, selectedProducts, setSelectedProducts }) => {
    const handleProductClick = (productId) => {
        if (selectedProducts.includes(productId)) {
            setSelectedProducts(selectedProducts.filter(id => id !== productId));
        } else {
            setSelectedProducts([...selectedProducts, productId]);
        }
    };

    const handleContinue = () => {
        onContinue(selectedProducts);
    };

    if (!isOpen) return null;

    const truncateName = (name) => {
        return name.length > 17 ? `${name.substring(0, 17)}...` : name;
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <h2>Выберите товары</h2>
                <div className={styles.productsContainer}>
                    <div className={styles.productList}>
                        {products.length > 0 && products.map(product => (
                            <div
                                key={product.id}
                                className={`${styles.productItem} ${selectedProducts.includes(product.id) ? styles.selected : ''}`}
                                onClick={() => handleProductClick(product.id)}
                            >
                                {product.image !== '' && <img src={product.image} alt={truncateName(product.name)} />}
                                <p>{truncateName(product.name)}</p>
                                {selectedProducts.includes(product.id) && <div className={styles.checkmark}>✔</div>}
                            </div>
                        ))}
                    </div>
                </div>
                <button className={styles.continueButton} onClick={handleContinue}>
                    Продолжить
                </button>
                <button className={styles.closeButton} onClick={onClose}></button>
            </div>
        </div>
    );
};

export default ProductsChoiceModal;
