import React, { useEffect, useState } from 'react';
import api from '../../api/api';
import Footer from '../../components/FooterComponent_v2';
import Header from '../../components/HeaderComponent_v2';
import NotificationComponent from '../../components/NotificationComponent';
import styles from './BannersPage.module.css';
import BannersComponent from './components/BannersComponent_v2';

const BannersPage = () => {
    const [banners, setBanners] = useState([])
    const [notificationText, setNotificationText] = useState('просто много текста текст есть текст');
    const [notificationState, setNotificationState] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('current_store_id')) {
            api.getBanners()
                .then((response) => {
                    if (response.code !== "ERR_BAD_REQUEST") {
                        var bannersData = response.data.map((banner) => {
                            return {
                                id: banner.id,
                                name: banner.name,
                                text: banner.text,
                                state: banner.state,
                                href: banner.href,
                                start_date: banner.start_date,
                                end_date: banner.end_date,
                                img: `https://storage.yandexcloud.net/botique/banners/${banner.id}/1?t=${Date.now()}`
                            }
                        });
                        setBanners(bannersData);
                    }
                })
                .catch(error => { console.log(error) });
        } else {
            setNotificationState(true);
            setNotificationText("Выберите магазин для отображения списка баннеров");
        }
    }, []);

    const handleBannerDelete = (bannerId) => {
        api.deleteBanner(bannerId)
            .then((response) => {
                if (response.code === "ERR_BAD_REQUEST") {
                    setNotificationState(true);
                    setNotificationText("При удалении баннера произошла ошибка. Перезагрузите страницу и попробуйте снова")
                } else {
                    setNotificationState(true);
                    setNotificationText("Баннер успешно удален!");
                    setBanners(banners.filter(banner => banner.id !== bannerId));
                }
            })
            .catch(error => { console.log(error) });
    }

    useEffect(() => {
        if (notificationState) {
            const timer = setTimeout(() => {
                setNotificationState(false);
                setNotificationText("");
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [notificationState])

    return (
        <div className={styles["banners-container"]}>
            <Header title={"Мои баннеры"} />
            <div>

                {notificationState ? <NotificationComponent text={notificationText} /> : null}

                {localStorage.getItem('current_store_id') ? (
                    <div>
                        <BannersComponent banners={banners} handlerDelete={handleBannerDelete} />
                    </div>
                ) : (null)}
            </div>
            <Footer />
        </div>
    );
};

export default BannersPage;