import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import api from '../../api/api';
import arrow from '../../assets/Vector.png';
import Footer from "../../components/FooterComponent_v2";
import Header from "../../components/HeaderComponent_v2";
import Loading from '../../components/Loading';
import PopupCustomComponent from '../../components/PopupCustomComponent';
import { error } from "../../components/notification";
import { set } from '../../shared/utils';
import styles from './ProductPage.module.css';
import { digitalAPI, productAPI } from './api/api';
import { BaseInfoForm } from "./components/BaseInfoForm/BaseInfoForm";
import { RequiredCharacteristicsForm } from './components/RequiredCharacteristicsForm/RequiredCharacteristicsForm';
import { VariationsBlock } from './components/VariationBlock/VariationBlock';
import { VariationCharacteristicsFrom } from './components/VariationCharacteristicsForm/VariationCharacteristicsForm';
import { uploadProductData } from './handlers/handlers';

export const ProductPage = () => {

    const nav = useNavigate();

    const { productID } = useParams();

    const [categories, setCategories] = useState([]);

    const [productData, setProductData] = useState({
        id: 'new',
        store: localStorage.getItem('current_store_id') ?? '',
        category: '',
        subcategory: '',
        tax: 'vat0',
        var1: '',
        var2: '',
        state: false,
        is_variative: false,
        fixed_characteristics: [],
        base_combo: {
            name: '',
            description: '',
            stock: 0,
            always_in_stock: false,
            vendor_code: '',
            price: 0,
            total_price: 0,
            discount: 0,
            weight: 400,
            depth: 10,
            height: 15,
            width: 15,
            photos: []
        },
        combos: []
    });

    const [variativeCharaceristics, setVariativeCharaceristics] = useState({
        var1: {
            id: "",
            name: "",
            variants: [],
        },
        var2: {
            id: "",
            name: "",
            variants: [],
        }
    });

    const [characteristicOptions, setCharacteristicOptions] = useState([]);
    const [isPhotoLoading, setIsPhotoLoading] = useState(false);
    const [loadingParams, setLoadingParams] = useState({ count: null, size: null });
    const [formErrors, setFormErrors] = useState({});
    const [isDigital, setIsDigital] = useState(false);

    useEffect(() => {

        const loadCategories = async () => await api.getCategoriesList().then((response) => {
            setCategories(response.data);
        }).catch(error => { console.log(error) });

        const loadCharacterisitcs = async () => await api.getCharacteristicsList().then((response) => {
            setCharacteristicOptions(response.data);
        });

        const loadProductData = async () => await productAPI.get(productID).then((response) => {
            const firstItemVariations = [];
            const secondItemVariations = [];

            setVariativeCharaceristics({
                var1: {
                    id: response.data.var1,
                    name: characteristicOptions.find(item => item.id === response.data.var1),
                    variants: [],
                },
                var2: {
                    id: response.data.var2,
                    name: characteristicOptions.find(item => item.id === response.data.var2),
                    variants: [],
                }
            });

            response.data.combos.forEach(combo => {
                firstItemVariations.push(combo.var1_value);
                secondItemVariations.push(combo.var2_value);
            });
            const newVariativeCharsState = { ...variativeCharaceristics }
            newVariativeCharsState.var1.variants = Array.from(new Set(firstItemVariations));
            newVariativeCharsState.var2.variants = Array.from(new Set(secondItemVariations));
            setVariativeCharaceristics(newVariativeCharsState);

            setProductData(prev => response.data);
        });

        digitalAPI.checkDigital().then(r => {
            setIsDigital(r.data.digital);
            if (r.data.digital) {
                digitalAPI.getDigitalCategories().then(r => { setCategories(r.data); })
            } else {
                loadCategories();
            }
            loadCharacterisitcs();
        });

        if (productID !== 'new') {
            loadProductData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productID]);

    const handleProductDataFieldChange = (fieldName, value) => {
        const numericFields = ['base_combo.stock', 'base_combo.price', 'base_combo.total_price', 'base_combo.depth', 'base_combo.height', 'base_combo.width', 'base_combo.weight'];
        if (numericFields.includes(fieldName)) {
            if (isNaN(value)) {
                return;
            }
            value = Number(value);
        }

        if (fieldName === 'category' | fieldName === 'subcategory' | fieldName === 'state') {
            setProductData(prev => ({ ...prev, [fieldName]: value }));
        } else if (fieldName.split('.').includes('name')) {
            if (value.length <= 64) {
                setProductData(set({ ...productData }, fieldName, value));
            } else {
                error('Ограничение по количеству символов - 64');
            }
        } else if (fieldName.split('.').includes('vendor_code')) {
            if (value.length <= 128) {
                setProductData(set({ ...productData }, fieldName, value));
            } else {
                error('Ограничение по количеству символов - 128');
            }
        } else {
            console.log(fieldName);
            setProductData(set({ ...productData }, fieldName, value));
        }
    };

    const checkStock = () => {
        if (productData.is_variative) {
            return productData.combos.reduce((acc, obj) => acc + obj.stock, 0) > 0;
        } else {
            return productData.base_combo.stock > 0;
        }
    };

    const handleSave = (newState) => {
        productData.state = newState;

        const errors = {};
        const combosFields = ['name', 'description', 'vendor_code', 'price', 'total_price'];
        combosFields.forEach((key) => {
            if (!productData.base_combo[key]) {
                errors[key] = 'Поле обязательно для заполнения';
            }
        });

        if (!isDigital) {
            const notDigitalFields = ['depth', 'height', 'width', 'weight'];
            notDigitalFields.forEach((key) => {
                if (!productData.base_combo[key]) {
                    errors[key] = 'Поле обязательно для заполнения';
                }
            });
        }

        // Проверка на заполнение полей в каждом комбо
        productData.combos.forEach((combo, index) => {
            combosFields.forEach((key) => {
                if (!combo[key]) {
                    errors[`combo-${index}-${key}`] = 'Поле обязательно для заполнения';
                }
            });
        });

        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            if (newState & !checkStock()) {
                productData.state = false;
                error('Чтобы выставить товар на продажу - измените его остаток!')
            }
            uploadProductData(productData, setIsPhotoLoading, nav, setLoadingParams);
        } else {
            error('Проверьте правильность введенных данных!');
        }
    };

    const handleDeleteProduct = () => {
        productAPI.delete(productID).then(r => {
            if (r.status === 204) {
                nav('/positions');
            }
        })
    };

    return (
        <>
            <Header title={"Форма товара"} />
            <div className={styles.pageWrapper}>
                <div className={styles.pageContentRow} style={{ justifyContent: 'flex-start', marginTop: '40px' }}>
                    <Link to={'/positions'} className={styles.backBtn}>
                        <img src={arrow} alt="" />
                        <span>Вернуться ко всем товарам</span>
                    </Link>
                </div>
                <div className={styles.pageContentRow}>
                    <h1 className={styles.title}>Добавление нового товара</h1>
                </div>
                <div className={styles.pageContentRow}>
                    <BaseInfoForm handleProductDataFieldChange={handleProductDataFieldChange} productData={productData} productId={productID} categories={categories} formErrors={formErrors} setFormErrors={setFormErrors} />
                </div>
                <div className={styles.pageContentRow}>
                    <RequiredCharacteristicsForm handleProductDataFieldChange={handleProductDataFieldChange} setProductData={setProductData} productData={productData} characteristicOptions={characteristicOptions} isDigital={isDigital} formErrors={formErrors} setFormErrors={setFormErrors} />
                </div>
                {productData.is_variative ? (
                    <>
                        <div className={styles.pageContentRow}>
                            <VariationCharacteristicsFrom productData={productData} setProductData={setProductData} setVariativeCharaceristics={setVariativeCharaceristics} variativeCharactrictics={variativeCharaceristics} characteristicOptions={characteristicOptions} />
                        </div>
                        {
                            productData.combos.length > 0 &&
                            <div className={styles.pageContentRow}>
                                <VariationsBlock productData={productData} setProductData={setProductData} handleProductDataFieldChange={handleProductDataFieldChange} formErrors={formErrors} isDigital={isDigital} />
                            </div>
                        }
                    </>
                ) : null}
                <div className={styles.pageContentRow}>
                    <div className={styles.mainBtns}>
                        <PopupCustomComponent triggerEl={<button type="button">Удалить товар</button>} title={"Вы действительно хотите удалить товар?"} text={"После удаления восстановить товар не получится"} handler={handleDeleteProduct} />
                        <button onClick={() => { handleSave(false); }}>Сохранить в черновик</button>
                        <button onClick={() => { handleSave(true); }}>Сохранить и продавать</button>
                    </div>
                </div>
            </div>
            <Footer />
            {isPhotoLoading && <Loading loadingParams={loadingParams} />}
        </>
    );
}