import React from 'react';
import Footer from '../../components/FooterComponent_v2';
import Header from '../../components/HeaderComponent_v2';
import InfoShop from './components/InfoShopComponent_v2';

function StoreViewPage() {
    return (
        <div>
            <Header title="Страница магазина" />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <InfoShop />
            </div>
            <Footer />
        </div>
    );
}

export default StoreViewPage;
