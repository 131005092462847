import React from 'react';
import Footer from '../../components/FooterComponent_v2';
import Header from '../../components/HeaderComponent_v2';

import Settings from './components/SettingsComponent';


function ProfilePage() {
    return (
        <div>
            <Header title={"Настройка профиля"} />
            <Settings />
            <Footer />
        </div>
    );
}

export default ProfilePage;
