import { useEffect } from "react";

export default function YaTokenServicePage() {

    useEffect(() => {
        // eslint-disable-next-line no-undef
        YaSendSuggestToken(
            'https://admin.thebotique.ru/',
            { flag: true }
        )
    }, []);

    return (<></>);
}