import React, { useEffect, useState } from 'react';
import styles from './SortButton.module.css';

const SortStates = {
    'DISABLED': null,
    'ASC': 'asc',
    'DESC': 'desc',
}

function SortButton({ initialState, onChange, sortingListName, currentSortingListName }) {
    const [sortState, setSortState] = useState(SortStates.DISABLED)

    useEffect(() => {
        setSortState(initialState ? initialState : SortStates.DISABLED);
    }, [initialState]);

    useEffect(() => {
        if (currentSortingListName !== sortingListName) {
            setSortState(initialState ? initialState : SortStates.DISABLED);
        }
    }, [currentSortingListName])


    return (
        <svg onClick={() => {
            if (sortState === SortStates.DISABLED) {
                setSortState(SortStates.ASC);
            }
            if (sortState === SortStates.ASC) {
                setSortState(SortStates.DESC);
            }
            if (sortState === SortStates.DESC) {
                setSortState(SortStates.DISABLED);
            }
            onChange(sortState);
        }} xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrows-sort" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" />
            <path d="M3 9l4-4l4 4m-4 -4v14" className={sortState === SortStates.ASC ? styles.active : ''} />
            <path d="M21 15l-4 4l-4-4m4 4v-14" className={sortState === SortStates.DESC ? styles.active : ''} />
        </svg>
    )
}

export default SortButton;