import React, { useEffect, useState } from "react";
import api from "../../../api/api";
import styles from "./TodoListComponent.module.css";

const TodoListComponent = ({ setInfo, setIsInfo }) => {
    const [todos, setTodos] = useState([]);
    const [filter, setFilter] = useState('all');


    const handleAddTodo = (e) => {
        e.preventDefault();
        const todoText = e.currentTarget.todo.value;
        if (todoText.trim() !== '') {
            if (todoText.length > 128) {
                setIsInfo(true);
                setInfo('Пожалуйста, введите задачу не длиннее 128 символов.');
            } else {
                api.createTask({ title: todoText, is_completed: false })
                    .then((res) => {
                        const newTodo = { id: res.data.id, title: todoText, is_completed: false };
                        setTodos([...todos, newTodo]);
                        e.target.reset();
                    })
                    .catch((e) => {
                        setIsInfo(true);
                        setInfo('Извините, при создании задачи произошла ошибка.');
                    })
            }
        }
    };

    const handleToggleComplete = (id) => {
        const currentTodo = todos.find(el => el.id === id);
        api.updateTask(id, { title: currentTodo.title, is_completed: !currentTodo.is_completed });

        const updatedTodos = todos.map((todo) =>
            todo.id === id ? { ...todo, is_completed: !todo.is_completed } : todo
        );
        setTodos(updatedTodos);
    };

    const handleFilter = (filter) => {
        setFilter(filter);
    };

    const activeBtn = (e) => {
        for (let item of e.target.parentElement.children) {
            item.classList.remove(styles.activeBtn);
        }
        e.target.classList.add(styles.activeBtn);
    }

    const filteredTodos = todos.filter((todo) => {
        if (filter === 'completed') {
            return todo.is_completed;
        } else if (filter === 'uncompleted') {
            return !todo.is_completed;
        } else {
            return true;
        }
    });

    useEffect(() => {
        api.getTODO()
            .then((response) => {
                var instructions = response.data.map((instruction) => { return { id: instruction.id, title: instruction.title, is_completed: instruction.is_completed } });

                setTodos(instructions);
            })
            .catch(error => { console.log(error) });

    }, []);

    return (
        <div className={styles.container}>
            <span className={styles.title}>Список задач</span>
            <div className={styles.list}>
                {filteredTodos.map((todo) => (
                    <div key={todo.id}>
                        <input
                            id={"toDoCheckBox_" + todo.id}
                            type="checkbox"
                            checked={todo.is_completed}
                            onChange={() => handleToggleComplete(todo.id)}
                        />
                        <label for={"toDoCheckBox_" + todo.id}>{todo.title}</label>
                        {/* <span></span> */}
                    </div>
                ))}
                {filteredTodos.length === 0 ? <span>{"Задач нет, можно отдохнуть :)"}</span> : null}
            </div>

            <div className={styles.btns}>
                <button onClick={(e) => { activeBtn(e); handleFilter('all') }} className={styles.activeBtn}>Все</button>
                <button onClick={(e) => { activeBtn(e); handleFilter('completed') }}>Выполненные</button>
                <button onClick={(e) => { activeBtn(e); handleFilter('uncompleted') }}>Активные</button>
            </div>

            <form onSubmit={handleAddTodo} className={styles.form}>
                <input type="text" name="todo" placeholder="Новая задача" />
                <button type="submit"></button>
            </form>
        </div>
    );
};
export default TodoListComponent;