import React, { useEffect, useState } from 'react';
import api from '../../api/api';
import Footer from '../../components/FooterComponent_v2';
import Header from '../../components/HeaderComponent_v2';
import Loading from '../../components/Loading';
import { error } from '../../components/notification';
import NotificationComponent from '../../components/NotificationComponent';
import ProductList from '../../components/ProductsComponent_v2';

const PositionsViewPage = () => {
    const [positions, setPositions] = useState([]);
    const [notificationText, setNotificationText] = useState('');
    const [notificationState, setNotificationState] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tariffStatus, setTariffStatus] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortParam, setSortParam] = useState({
        sortBy: null,
        orderBy: null,
    });


    const handleDelete = (id) => {
        api.deleteProductV2(id).then((response) => { setTariffStatus(true); }).catch((errors) => { console.log(errors) })
        const updatedPositions = positions.filter((position) => position.id !== id);
        setPositions(updatedPositions);
    };

    const loadProducts = () => {
        setIsLoading(true);
        api.getProductsV2(currentPage, sortParam.sortBy, sortParam.orderBy).then(r => {
            setIsLoading(false);
            if (r.code === "ERR_BAD_REQUEST") {
                setNotificationState(true);
                setNotificationState("Выберите магазин для отображения списка товаров");
            } else if (r.status === 200) {
                setTariffStatus(r.data.tariff);
                setPositions(r.data.products);
                setTotalPages(r.data.total_pages);
            } else {
                error('Произошла ошибка...');
            }
        }).catch((errors) => { console.log(errors) });
    };

    useEffect(() => {
        if (localStorage.getItem('current_store_id')) {
            // loadProducts();
        } else {
            setNotificationState(true);
            setNotificationText("Выберите магазин для отображения списка товаров");
        }
    }, []);

    useEffect(() => {
        if (notificationState) {
            const timer = setTimeout(() => {
                setNotificationState(false);
                setNotificationText("");
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [notificationState]);

    const handleSort = (type) => {
        if (sortParam.sortBy === type) {
            if (sortParam.orderBy === null) {
                setSortParam({ sortBy: type, orderBy: 'desc' });
            } else {
                setSortParam({ sortBy: null, orderBy: null });
            }
            return;
        }
        setSortParam({ sortBy: type, orderBy: null });
    };

    useEffect(() => {
        loadProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, sortParam]);

    return (
        <div>
            <Header title={"Мои товары"}></Header>
            {notificationState ? <NotificationComponent text={notificationText} /> : null}
            {localStorage.getItem('current_store_id') ? (<div style={{ width: "100%", display: "flex", justifyContent: "center" }}><ProductList products={positions} setProducts={setPositions} handleDelete={handleDelete} setInfo={setNotificationText} setIsInfo={setNotificationState} tariff={tariffStatus} handleSort={handleSort} selectedSort={sortParam.sortBy} totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} setTariffStatus={setTariffStatus} /></div>) : (null)}
            <Footer />
            {isLoading ? <Loading /> : null}
        </div>
    );
};

export default PositionsViewPage;
