import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "../styles/DropDownComponent_v2.module.css";
import api from '../api/api';

const Dropdown = ({ options, selectedOption, text, tariff }) => {
  const nav = useNavigate();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    setIsOpen(false);
    localStorage.setItem('current_store_id', option.id);
    const urlPath = window.location.pathname.split('/');
    if (urlPath[1] === 'store' & urlPath.length === 3) {
      if (urlPath[2] !== 'new' & urlPath[2] !== 'set-design') {
        api.getStoreSign(option.id).then(r => {
          if (r.data) {
            localStorage.setItem('_signiture_for_store_data', r.data.signiture);
          }
        }).then(() => nav(`/store/${option.id}`));
      }
    }
    api.getStoreSign(option.id).then(r => {
      if (r.data) {
        localStorage.setItem('_signiture_for_store_data', r.data.signiture);
      }
    }).then(() => window.location.reload());
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handlerForAddShop = () => {
    if (tariff.design_choice) {
      nav('/store/set-design');
    } else {
      nav('/store/new');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <button className={styles.dropdownToggle} onClick={toggleDropdown} type="button">
        {selectedOption ? selectedOption : text}
      </button>
      {isOpen && (
        <ul className={styles.dropdownMenu}>
          {tariff.allow_stores && <li onClick={handlerForAddShop}>+ Создать магазин</li>}
          {options.map((option, index) => (
            <li className={styles.dropdownItem} key={index} onClick={() => selectOption(option)} style={option.name === selectedOption ? { background: "linear-gradient(0deg, rgba(60, 173, 255, 0.20) 0%, rgba(60, 173, 255, 0.20) 100%), #FAFAFA" } : null}>
              {option.name}
            </li>
          ))}
          <li onClick={() => { nav('/stores') }}>Все магазины</li>
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
