import React from "react";
import { v4 as uuid } from 'uuid';
import styles from "./AnswersComponent_v2.module.css";
import AnswerComponent from "./AnswerItemComponent_v2";

function Answers() {
    const data = [
        { question: "Как создать бота в телеграмм?", answer: "Откройте Telegram и найдите в поиске бота с именем '@BotFather'. Это официальный бот Telegram для создания и управления ботами. Нажмите на его имя, чтобы начать чат с ним. В чате с @BotFather напишите (или выберите) команду /newbot. Следуйте инструкциям: введите имя для вашего бота (должно оканчиваться на 'bot') и затем уникальное имя пользователя для вашего бота (должно заканчиваться на '_bot'). Сохраните эти данные, они понадобятся при создании магазина. После успешного создания бота, @BotFather предоставит вам токен. Этот токен — ключ, который позволит вашему боту взаимодействовать с Telegram API. Обязательно сохраните этот токен - он также понадобится при создании магазина." },
        { question: "Как загрузить товары из файла?", answer: 'Для того, чтобы загрузить товары из файла - перейдите в раздел "товары" и нажмите на "шаблон", после этого вам нужно внести информацию о ваших товарах в наш фирменный шаблон. Затем, на странице "товары" выберите "Загрузить из файла", после успешной загрузки - товары отобразятся в списке.' },
        { question: "Как добавить удалить товар?", answer: 'Выберите нужный магазин в разделе "Мой магазин:", затем перейдите в раздел "Товары", найдите нужный товар и нажмите на изображение крестика "x" справа. Подтвердите действие - товар исчезнет из вашего списка, а также из каталога магазина.' },
        { question: "Пропала кнопка добавления товара, что делать?", answer: 'Это может быть связано с тем, что вы достигли лимита по количеству товаров в магазине, вам нужно изменить уровень подписки, чтобы добавить больше товаров.' },
        { question: "Почему товар не отображается в каталоге магазина?", answer: 'Найдите нужный товар в разделе "товары" и убедитесь, что измените его статус на "Продается" - теперь он будет отображаться в каталоге.' },
        { question: 'Не удается изменить статус товара на "Продается", в чем дело?', answer: 'Для того, чтобы изменить статус товара на "Продается" необходимо увеличить остаток товара в графе "Количество на складе".' },
        { question: 'Как изменить дизайн магазина?', answer: 'Чтобы изменить внешний вид вашего магазина перейдите в раздел "Все магазины", выберите нужный магазин и под изображением телефона нажмите "Перейти к выбору оформления магазина" - выберите понравившийся вариант и нажмите "Далее".' },
        { question: 'Почему у меня нет возможности изменить дизайн магазина?', answer: 'Возможность выбора оформления магазина доступна не во всех тарифах.' },
        { question: "Как подключить доставку Boxberry?", answer: 'Первым делом создайте личный кабинет Boxberry и получите токен продавца, подробнее: https://boxberry.ru/e-commerce/it-resenia/licnyj-kabinet-boxberry . Полученный токен используйте при создании магазина.' },
    ];
    return (
        <section className={styles.answers}>
            <h2 className={styles.answersTitle}>часто задаваемые вопросы</h2>
            <div className={styles.answersContainer}>
                {data.map(el => {
                    return <AnswerComponent key={uuid()} question={el.question} answer={el.answer} />
                })}
            </div>
        </section>
    )
}
export default Answers;