import React, { useEffect } from "react";
import Videos from "./VideosComponent_v2";
import RequestForm from "./HelpFormComponent_v2";
import Answers from "./AnswersComponent_v2";
import styles from "./HelpComponent_v2.module.css";

function Help() {
    useEffect(() => {
        document.body.classList.add(styles.bodyBlock);

        return () => {
            document.body.classList.remove(styles.bodyBlock);
        };
    }, []);
    return (
        <div className={styles.help}>
            <h1 className={styles.helpTitle}>
                поддержка
            </h1>
            <Videos />
            <Answers />
            <RequestForm />
        </div>
    )
}
export default Help;