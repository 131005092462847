import React, { useState } from "react";
import api from "../../../api/api";
import FormatNumbers from "../../../components/FormatNumbersComponent";
import ImageComponent from "../../../components/ImageCustomComponet";
import styles from "./MobileOrderItemComponent.module.css";

function MobileOrderItem({ position }) {
    const [imgPath, setImgPath] = useState('');
    api.getOrderInfoImg(position.nm_id).then(r => {
        if (r.status === 200) {
            setImgPath(r.data.url);
        }
    }).catch(error => { console.log(error) });
    return (
        <div className={styles.item}>
            <div className={styles.info}>
                <ImageComponent imagePath={imgPath} className={styles.img} ourWidth={80} />
                <span>{position.name}</span>
            </div>

            <div className={styles.option}>
                <span>{position.var1_name === "" ? null : position.var1_name + ":"}</span>
                <span>{position.var1}</span>
            </div>
            <div className={styles.option}>
                <span>{position.var2_name === "" ? null : position.var2_name + ":"}</span>
                <span>{position.var2}</span>
            </div>
            <div className={styles.option}>
                <span>Статус:</span>
                <span>оформлен</span>
            </div>
            <div className={styles.option}>
                <span>Количество:</span>
                <span>{position.quantity + " шт."}</span>
            </div>
            <div className={styles.priceContainer}>
                <div className={styles.price}>
                    <span>Цена за шт:</span>
                    <FormatNumbers number={position.price / 100 + " ₽"} styles={{ color: "gray", fontWeight: 400 }} />
                </div>
                <div className={styles.sale}>
                    <span>Скидка:</span>
                    <FormatNumbers number={(position.price - position.total_price) / 100 + " ₽"} styles={{ color: "green", fontWeight: 400 }} />
                </div>
                <div className={styles.totalPrice}>
                    <span>Цена со скидкой:</span>
                    <FormatNumbers number={position.total_price / 100 + " ₽"} styles={{ fontWeight: 400 }} />
                </div>
            </div>

            <div className={styles.sum}>
                <span>Итого:</span>
                <FormatNumbers number={position.total_price / 100 * position.quantity + " ₽"} />
            </div>
        </div>
    )
}
export default MobileOrderItem;