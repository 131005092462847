import React from "react";
import Footer from "../../components/FooterComponent_v2";
import Header from "../../components/HeaderComponent_v2";
import MarketingComponent from "./components/MarketingComponent";

function MarketingPage() {
    return (
        <div>
            <Header title={"Клиенты и рассылки"}></Header>
            <MarketingComponent />
            <Footer />
        </div>
    )
}
export default MarketingPage;