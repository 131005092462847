import { useEffect, useState } from 'react';
import DropdownForForm from '../../../../../components/DropDownForFormComponent';
import { error } from '../../../../../components/notification';
import { addFixedCharacteristic, removeFixedCharacteristic } from '../../../handlers/handlers';
import styles from './AdditionalCharacteristicsList.module.css';

export const AdditionalCharacteristicsList = ({ productData, characteristicOptions, setProductData }) => {

    const [currentCharacteristicState, setCurrentCharacteristicState] = useState({
        characteristic: '',
        product: '',
        value: ''
    });

    const handleAddCharacteristic = () => {
        if (currentCharacteristicState.characteristic !== '' & currentCharacteristicState.value !== '') {
            console.log(currentCharacteristicState.characteristic);

            if (['eaeee0a1-0772-47e1-810b-e1e000e900e2', '010c5989-62c2-4a8f-8c9e-6d0b421a2823'].includes(currentCharacteristicState.characteristic)) {
                if (!(/^\d+$/.test(currentCharacteristicState.value) && parseInt(currentCharacteristicState.value)) >= 1) {
                    error('Неверное число')
                    return;
                }
            }



            addFixedCharacteristic(currentCharacteristicState, setProductData);
            setCurrentCharacteristicState(prevState => { return { ...prevState, value: '' } });
        }
    };

    const handleDeleteCharacteristic = (index) => {
        removeFixedCharacteristic(index, productData, setProductData);
    };

    useEffect(() => {
        if (productData.id) {
            setCurrentCharacteristicState(prevState => { return { ...prevState, product: productData.id } });
        }
    }, [productData.id]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.formCharacteristics}>
                <DropdownForForm
                    options={characteristicOptions}
                    placeHolder="Выберите характеристику"
                    setChoice={(id) => {
                        setCurrentCharacteristicState(prevState => { return { ...prevState, characteristic: id } });
                    }}
                />
                <input
                    type="text"
                    placeholder="Введите значение"
                    value={currentCharacteristicState.value}
                    onChange={({ target }) => { setCurrentCharacteristicState(prevState => { return { ...prevState, value: target.value } }); }}
                    onKeyUp={({ key }) => {
                        if (key === 'Enter') {
                            handleAddCharacteristic();
                        }
                    }}
                />
                <button type="button" onClick={handleAddCharacteristic} />
            </div>
            <div className={styles.formCharacteristicsList}>
                {productData.fixed_characteristics && productData.fixed_characteristics.map((item, idx) => {
                    if (idx % 2 === 0) {
                        return (
                            <div key={idx} className={styles.characterDub}>
                                <span>{characteristicOptions.length > 0 && characteristicOptions.find(el => el.id === item.characteristic).name}: </span>
                                <span>{item.value}</span>
                                <button type="button" onClick={() => handleDeleteCharacteristic(idx)} />
                            </div>
                        )
                    }
                    return (
                        <div key={idx} className={styles.character}>
                            <span>{characteristicOptions.length > 0 && characteristicOptions.find(el => el.id === item.characteristic).name}: </span>
                            <span>{item.value}</span>
                            <button type="button" onClick={() => { handleDeleteCharacteristic(idx) }} />
                        </div>
                    )
                })}
            </div>
        </div>
    );
}