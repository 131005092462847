import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import api from '../../api/api';
import logo from '../../assets/botq.svg';
import styles from "./EmailConfirmationPage.module.css";

const EmailConfirmationPage = () => {
    const [message, setMessage] = useState('');
    const { token } = useParams();

    useEffect(() => {
        console.log(token)
        if (token) {
            api.emailConfirm(token)
                .then((response) => {
                    if (response.status === 200) {
                        setMessage('Ваш email успешно подтверждён! Теперь вы можете авторизоваться.');
                    } else {
                        setMessage('Произошла ошибка при подтверждении email.');
                    }
                })
                .catch(error => { console.log(error) });
        } else { setMessage('Что-то пошло не так...'); }
    }, []);

    return (
        <div className={styles["container"]}>
            <div className={styles["confirmation-page"]}>
                <div className={styles["logo"]}>
                    <img src={logo} alt="Логотип" />
                </div>
                <h1>Подтверждение Email</h1>
                <p className={message.includes('успешно') ? styles['success'] : styles['error']}>{message}</p>
                {message.includes('успешно') && (
                    <p>
                        Теперь вы можете <Link to='/'>авторизоваться</Link>.
                    </p>
                )}
            </div>
        </div>
    );
};

export default EmailConfirmationPage;
