import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';
import ErrorMessage from '../../components/ErrorMessage';
import Footer from '../../components/FooterComponent_v2';
import Header from '../../components/HeaderComponent_v2';
import InfoMessage from '../../components/InfoMessage';
import NotificationComponent from '../../components/NotificationComponent';
import styles from './StoreManagementPage.module.css';
import ShopList from './components/ShopsComponent_v2';


const StoreManagementPage = () => {
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [storesList, setStoresList] = useState([]);
    const [notificationText] = useState('');
    const [notificationState] = useState(false);

    const [infoText, setInfoText] = useState("");
    const [isInfo, setIsInfo] = useState(false);

    const [tariffStatus, setTariffStatus] = useState({ status: true, design_choice: false });

    const nav = useNavigate();

    const handleDelete = (storeId) => {
        api.deleteStore(storeId)
            .then((response) => {
                if (response.code === "ERR_BAD_REQUEST") {
                    setIsError(true);
                    setErrorText("При удалении магазина произошла ошибка.");
                } else {
                    setIsInfo(true);
                    setInfoText("Магазин успешно удален!");
                    setStoresList(storesList.filter(store => store.id !== storeId));
                    if (localStorage.getItem('current_store_id') === storeId) {
                        localStorage.setItem('current_store_id', storesList[0].id);
                    }

                    setTariffStatus((prev) => ({
                        ...prev,
                        status: true,
                        allow_stores: true
                    }))
                }
            })
            .catch(error => { console.log(error) });
    };

    const handleView = (storeId) => {
        nav(`/store/${storeId}`);
    };

    useEffect(() => {
        api.getStoresList()
            .then((response) => {
                if (response.code === "ERR_BAD_REQUEST") {
                    setIsError(true);
                    setErrorText("Не удалось загрузить магазины.")
                } else {
                    var responseStores = response.data.stores.map((store) => {
                        return { id: store.id, name: store.name, status: store.state, link: store.href };
                    });
                    setStoresList(responseStores);
                    setTariffStatus(response.data.tariff);
                }
            })
            .catch(error => { console.log(error) });
        document.body.classList.add(styles.bodyRuleDisappear);

        return () => {
            document.body.classList.remove(styles.bodyRuleDisappear);
        };
    }, []);

    useEffect(() => {
        if (isError) {
            const timer = setTimeout(() => {
                setIsError(false);
                setErrorText("");
            }, 3000);
            return () => clearTimeout(timer);
        }

        if (isInfo) {
            const timer = setTimeout(() => {
                setIsInfo(false);
                setInfoText("");
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [isError, isInfo])

    return (
        <div>
            <Header title={"Мои магазины"} />
            <div className={styles['store-management-page']}>
                {isInfo && <InfoMessage message={infoText} isVisible={isInfo} />}
                {isError && <ErrorMessage message={errorText} />}
            </div>
            <ShopList shops={storesList} deleteHandler={handleDelete} viewHandler={handleView} tariff={tariffStatus} />
            {notificationState ? <NotificationComponent text={notificationText} /> : null}
            <Footer />
        </div>
    );
};

export default StoreManagementPage;
