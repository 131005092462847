import React, { useEffect } from "react";
import styles from './Notification.module.css';

export const NotificationType = {
    info: "info",
    success: "success",
    warning: "warning",
    error: "error",
};

const timeToClose = 3000;

/**
 * config - [{id: string, text: string, value: string}]
 * onChange - change value callback
 */
function Notification({ type, animated, children, handleClose }) {

    useEffect(() => {
        const timeout = setTimeout(handleClose, timeToClose);

        return () => clearTimeout(timeout);
    })

    return (<div className={`${styles.wrapper} ${styles[type]} ${animated ? styles.slideIn : ''}`}>
        <div className={styles.content}>
            {type === NotificationType.error && <span className={styles.errorWarningText}>Внимание! </span>}
            {children}
        </div>
        <div className={styles.closeButton} onClick={handleClose}>
            <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Frame 872">
                    <path id="Line 32" d="M1.86719 14.1367L14.1399 1.86399" stroke="#000000" strokeWidth={1.5} />
                    <path id="Line 33" d="M14.1328 14.1367L1.86009 1.86399" stroke="#000000" strokeWidth={1.5} />
                </g>
            </svg>
        </div>
    </div >)
}

export default Notification;