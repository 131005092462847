import React from 'react';
import styles from '../styles/Loading.module.css';

const Loading = ({ loadingParams }) => {
    console.log(loadingParams);
    return (
        <div className={`${styles.loadingOverlay} ${styles.show}`}>
            <div className={styles.loaderContainer}>
                {(loadingParams?.count & loadingParams?.size) ? <span>Загрузка {loadingParams.count} / {loadingParams.size}...</span> : null}
                <div className={styles.loader}></div>
                {/* <img className={styles.centerImage} src={botq} alt="Center" /> */}
            </div>
        </div>
    );
};

export default Loading;