import React, { useState } from 'react';
import styles from './GroupNameModal.module.css';

const GroupNameModal = ({ isOpen, onClose, onContinue, newGroupName, setNewGroupName, isProductsLoading }) => {
    const [error, setError] = useState(false);

    const handleContinue = () => {
        if (newGroupName === '') {
            setError(true);
            return;
        }
        onContinue();
    };

    const changeName = ({ target }) => {
        setError(false);
        setNewGroupName(target.value)
    };

    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <h2>Введите название для группы</h2>
                <div className={styles.field}>
                    <input className={error ? styles.error : null} value={newGroupName} onChange={changeName}></input>
                    {error && <span>Заполните поле</span>}
                </div>
                <button className={styles.continueButton} onClick={handleContinue}>
                    {isProductsLoading ? <div className={styles.loader} /> : 'Продолжить'}
                </button>
                <button className={styles.closeButton} onClick={onClose}></button>
            </div>
        </div >
    );
};

export default GroupNameModal;
