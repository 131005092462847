import styles from "../styles/Pagination.module.css";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    const generatePageNumbers = () => {
        const pageNumbers = [];
        const displayRange = 1;

        for (let i = 1; i <= totalPages; i++) {
            if (i === 1 || i === totalPages || (i >= currentPage - displayRange && i <= currentPage + displayRange)) {
                pageNumbers.push(i);
            } else if (pageNumbers[pageNumbers.length - 1] !== '...') {
                pageNumbers.push('...');
            }
        }

        return pageNumbers;
    };

    if (totalPages < 2) {
        return <></>;
    }

    return (
        <div className={styles.pagination}>
            Страница: {generatePageNumbers().map((pageNumber, index) => (
                <span key={index} className={pageNumber === currentPage ? styles.active : ''} onClick={() => onPageChange(pageNumber)}>
                    {pageNumber}
                </span>
            ))}
        </div>
    );
};

export default Pagination;
