
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import api from "../../../api/api";
import InfoMessage from "../../../components/InfoMessage";
import CustomCheckBox from '../../../components/CustomCheckBoxComponent';
import styles from "./HelpFormComponent_v2.module.css";

const RequestForm = () => {
  const [agree, setAgree] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSend, setIsSend] = useState(false);
  const [infoText, setInfoText] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const requestData = {
      "name": name,
      "email": email,
      "message": message
    }

    api.sendEmail(requestData)
      .then((response) => {
        setIsSend(true);
        setInfoText("Сообщение отправлено! С вами скоро свяжутся.");
        setEmail('');
        setName('');
        setMessage('');
      })
      .catch((error) => { console.log(error) });
  };


  return (
    <div className={styles.formContainer}>
      <InfoMessage message={infoText} isVisible={isSend} />
      <span className={styles.formTitle}>
        Не нашли ответа на свой вопрос? Оставьте своё обращение, заполнив форму, и мы обязательно с вами свяжемся и постараемся решить любой ваш вопрос.
      </span>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.info}>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Ваше имя" required />
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Адрес электронной почты" required />
          {/* <InputMask
            mask="+7 (999) 999-99-99"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Ваш телефон"
            required
          /> */}
        </div>
        <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Введите Ваш вопрос" />
        <button type="submit" className={styles.send}>Оставить заявку</button>
        <div className={styles.check}>
          <CustomCheckBox checked={agree} onChange={() => setAgree(!agree)} required={true} />
          <span>Оставляя заявку, вы даете согласие на <Link to={'https://docs.thebotique.ru/privacy-policy-agreement'} target="_blank">согласие на обработку персональных данных</Link></span>
        </div>
      </form>
    </div>

  );
};

export default RequestForm;
