import { useState } from 'react';
import { addCardCombo, removeCard } from '../../handlers/handlers';
import { CharacteristicItemsList } from './CharacteristicItemsList/CharacteristicItemsList';
import styles from './VariationCharacteristicsFrom.module.css';
import { VariationsDescriptionBlock } from './VariationsDescriptionBlock/VariationsDescriptionBlock';

export const VariationCharacteristicsFrom = ({ productData, setProductData, variativeCharactrictics, setVariativeCharaceristics, characteristicOptions }) => {
    const [selectedVar1Variants, setSelectedVar1Variants] = useState([]);
    const [selectedVar2Variants, setSelectedVar2Variants] = useState([]);

    const handleSubmit = () => {
        if (selectedVar1Variants.length > 0 & variativeCharactrictics.var2.variants.length === 0) {
            selectedVar2Variants.push('');
        } else if (variativeCharactrictics.var1.variants.length === 0 & selectedVar2Variants.length > 0) {
            selectedVar1Variants.push('');
        }

        selectedVar1Variants.forEach(var1 => {
            selectedVar2Variants.forEach(var2 => {
                addCardCombo(var1, var2, productData, setProductData);
            });
        });
    };

    const handleDelete = (varName, varValue) => {
        productData.combos.forEach((item, index) => {
            if (item[`${varName}_value`] === varValue) {
                removeCard(index, productData, setProductData);
            }
        });

        setVariativeCharaceristics(prevState => ({
            ...prevState,
            [varName]: { variants: prevState[varName].variants.filter(item => item !== varValue) }
        }))
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                ДОБАВЛЕНИЕ ВАРИАЦИЙ
            </div>
            <div className={styles.variationForm}>
                <CharacteristicItemsList
                    key={'chars_list_1'}
                    productData={productData}
                    setProductData={setProductData}
                    variations={variativeCharactrictics.var1.variants}
                    setVariativeCharaceristics={setVariativeCharaceristics}
                    setSelectedVariants={setSelectedVar1Variants}
                    name={'var1'}
                    handleDelete={handleDelete}
                    characteristicOptions={characteristicOptions}
                    title={'Характеристика 1'}
                />
                <CharacteristicItemsList
                    key={'chars_list_2'}
                    productData={productData}
                    setProductData={setProductData}
                    variations={variativeCharactrictics.var2.variants}
                    setVariativeCharaceristics={setVariativeCharaceristics}
                    setSelectedVariants={setSelectedVar2Variants}
                    handleDelete={handleDelete}
                    name={'var2'}
                    characteristicOptions={characteristicOptions}
                    title={'Характеристика 2'}
                />
                <VariationsDescriptionBlock handleSubmit={handleSubmit} />
            </div>
        </div>
    );
}