import styles from './RequiredCharacteristicsList.module.css';

export const RequiredCharacteristicsList = ({ productData, onChange, formErrors }) => {

    return (
        <div className={styles.wrapper}>
            <div className={styles.formGroupShort}>
                <span>Длина, см</span>
                <input
                    type="text"
                    min="0"
                    name="base_combo.depth"
                    value={productData.base_combo.depth}
                    onChange={onChange}
                    required
                />
                {formErrors.depth && <span className={styles.fieldError}>{formErrors?.depth}</span>}
            </div>
            <div className={styles.formGroupShort}>
                <span>Высота, см</span>
                <input
                    type="text"
                    min="0"
                    name="base_combo.height"
                    value={productData.base_combo.height}
                    onChange={onChange}
                    required
                />
                {formErrors.height && <span className={styles.fieldError}>{formErrors?.height}</span>}
            </div>
            <div className={styles.formGroupShort}>
                <span>Ширина, см</span>
                <input
                    type="text"
                    min="0"
                    name="base_combo.width"
                    value={productData.base_combo.width}
                    onChange={onChange}
                    required
                />
                {formErrors.width && <span className={styles.fieldError}>{formErrors?.width}</span>}
            </div>
            <div className={styles.formGroupShort}>
                <span>Вес, гр</span>
                <input
                    type="text"
                    min="0"
                    name="base_combo.weight"
                    value={productData.base_combo.weight}
                    onChange={onChange}
                    placeholder="0"
                    required
                />
                {formErrors.weight && <span className={styles.fieldError}>{formErrors?.weight}</span>}
            </div>
        </div>
    );
}