import React, { useEffect, useState } from 'react';
import api from '../../api/api';
import Footer from '../../components/FooterComponent_v2';
import Header from '../../components/HeaderComponent_v2';
import Tooltips from './components/ToolTipComponent';

function NotificationPage() {

    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        api.getNotificationsData()
            .then((r) => {
                setNotifications(r.data);
            })
            .catch((e) => { })
    }, [])

    return (
        <div>
            < Header title={"Уведомления продавца"} />
            <Tooltips notifications={notifications} setNotifications={setNotifications} />
            < Footer />
        </div>
    );
}

export default NotificationPage;
