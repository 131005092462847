import styles from './VariationsDescriptionBlock.module.css';

export const VariationsDescriptionBlock = ({ handleSubmit }) => {

    return (
        <div className={styles.wrapper}>
            <div>
                Для каждого товара вы можете выбрать 2 вариативные характеристики. Для этого выберите значения в полях “Характеристика 1” и “Характеристика 2”, а затем введите значения характеристики. Чтобы добавить вариации к товару поставьте галочку рядом со значениями из обоих столбцов, после чего нажмите кнопку “Создать”.
            </div>
            <button className={styles.button} onClick={handleSubmit}>
                Создать
            </button>
        </div>
    );
}