import React, { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from 'react-router-dom';
import api from '../../api/api';
import Footer from '../../components/FooterComponent_v2';
import Header from '../../components/HeaderComponent_v2';
import Action from './components/ActionComponent_v2';
import BannerForm from './components/BannerFormComponent_v2';

function EditBannerPage() {
    const { bannerID } = useParams();
    const [isProduct, setIsProduct] = useState(true);

    useEffect(() => {
        if (bannerID !== undefined) {
            if (bannerID !== "new") {
                api.getBanner(bannerID)
                    .then((response) => {
                        if (response.data) {
                            if (response.data.text === '') {
                                setIsProduct(true);
                            } else {
                                setIsProduct(false);
                            }
                        }
                    })
                    .catch(error => { console.log(error) });
            }
        }
    }, [bannerID]);

    return (
        <div>
            <Header title={"Форма баннера"} />
            {isProduct ? < BannerForm /> : <Action />}
            <Footer />
        </div>
    );
}

export default EditBannerPage;