import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import api from '../../../api/api';
import FormatNumbers from "../../../components/FormatNumbersComponent";
import OrderItemComponent from "./OrderItemComponent";
import OrderItemMobile from "./OrderItemMobileComponent";
import styles from "./OrderListComponent.module.css";
import SortButton from "./SortButton/SortButton";

const FieldNameForSorting = {
    CREATED_AT: 'created_at',
    DELIVERY_TYPE: 'delivery.type',
    DELIVERY_CILENT_NAME: 'delivery.client_name',
    TOTAL_AMOUNT: 'total.amount'
}

const SelectedPeriod = {
    WEEK: 'week',
    TWO_WEEKS: 'two-weeks',
    MONTH: 'month',
    THREE_MONTHS: 'three-months',
    ALL_TIME: 'all-time',
}

function buildPageNumbersRow(activePage, pagesCount, handlePageClick) {
    let isSeparatorInserted = false;
    const range = {
        from: activePage === 1 ? 1 : activePage - 1,
        to: pagesCount,
        *[Symbol.iterator]() {
            for (let value = this.from; value <= this.to; value++) {
                if (this.to > 6 && value - this.from > 2 && !isSeparatorInserted && this.to - this.from > 6) {
                    yield (<li>...</li>);
                    value = this.to - 3;
                    isSeparatorInserted = true;
                } else {
                    yield (
                        <li key={value} onClick={() => handlePageClick(value)} className={activePage === (value) ? styles.activePage : null}>
                            {value}
                        </li>
                    );
                }
            }
        }
    }
    return [...range];
}

function trimOrdersByPeriod(selectedPeriod, orders) {
    const now = new Date();
    // console.log('DEBUG', orders);
    switch (selectedPeriod) {
        case SelectedPeriod.WEEK:
            return orders.filter(el => Number(new Date(el[FieldNameForSorting.CREATED_AT])) > Number(new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() - 7,
            )) / 1000);
        case SelectedPeriod.TWO_WEEKS:
            return orders.filter(el => Number(new Date(el[FieldNameForSorting.CREATED_AT])) > Number(new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() - 14,
            )) / 1000);
        case SelectedPeriod.MONTH:
            return orders.filter(el => Number(new Date(el[FieldNameForSorting.CREATED_AT])) > Number(new Date(
                now.getFullYear(),
                now.getMonth() - 1,
                now.getDate(),
            )) / 1000);
        case SelectedPeriod.THREE_MONTHS:
            return orders.filter(el => Number(new Date(el[FieldNameForSorting.CREATED_AT])) > Number(new Date(
                now.getFullYear(),
                now.getMonth() - 3,
                now.getDate(),
            )) / 1000);
        default:
            return orders
    }
}

function OrderListComponent({ stats }) {
    const [activePage, setActivePage] = useState(1);

    const [orders, setOrders] = useState([]);
    const [selectedByDateOrders, setSelectedByDateOrders] = useState([]);

    // const [isRequestPending, setRequestPending] = useState(false);

    const [selectedPeriod, setSelectedPeriod] = useState(SelectedPeriod.ALL_TIME);
    const [sortedListName, setSortedListName] = useState('');
    const [sortedState, setSortedState] = useState('');
    const [searchText, setSearchText] = useState('');

    const storeId = localStorage.getItem('current_store_id');

    const countsForOrders = [2, 10, 20, 50];
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pagesCount, setPagesCount] = useState(1);

    // вынести общую часть в функцию

    useEffect(() => {
        if (storeId) {
            api.getOrdersv2(storeId, searchText, activePage, itemsPerPage, sortedListName, sortedState).then(r => {
                setOrders(r.data.orders);
                setPagesCount(r.data.total_pages);
            }).catch((errors) => {
                console.log('Ошбика заказа');
                // добавить нотификашку
            });
        }
    }, [storeId, activePage, itemsPerPage, searchText, sortedListName, sortedState]);

    useEffect(() => {
        setSelectedByDateOrders(trimOrdersByPeriod(selectedPeriod, orders));
    }, [orders])

    const handleSearchTextChange = (e) => {
        setActivePage(1)
        setSearchText(e.target.value ?? '');
    }

    // Функция для обновления активной страницы
    const handlePageClick = (page) => {
        setActivePage(page);
    };

    // Функция для обновления количества товаров на странице
    const handleItemsPerPage = (count) => {
        localStorage.setItem('__OrderItemsPerPage', count);
        setItemsPerPage(count);
    };


    const handlerForDate = (e) => {
        setSelectedPeriod(e.target.name);
        setSelectedByDateOrders(trimOrdersByPeriod(e.target.name, orders));
    };

    useEffect(() => {
        const prevCount = Number(localStorage.getItem('__OrderItemsPerPage'));
        if (prevCount) {
            if (countsForOrders.includes(prevCount)) {
                setItemsPerPage(prevCount);
            }
        }

        document.body.classList.add(styles.bodyRuleDisappear);

        return () => {
            document.body.classList.remove(styles.bodyRuleDisappear);
        };

    }, [])

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Информация по заказам</h1>
            <div className={styles.info}>
                <div className={styles.days}>
                    <button name={SelectedPeriod.WEEK} onClick={handlerForDate} className={selectedPeriod === SelectedPeriod.WEEK ? styles.activeDay : ''} >7 дней</button>
                    <button name={SelectedPeriod.TWO_WEEKS} onClick={handlerForDate} className={selectedPeriod === SelectedPeriod.TWO_WEEKS ? styles.activeDay : ''}>14 дней</button>
                    <button name={SelectedPeriod.MONTH} onClick={handlerForDate} className={selectedPeriod === SelectedPeriod.MONTH ? styles.activeDay : ''}>1 месяц</button>
                    <button name={SelectedPeriod.THREE_MONTHS} onClick={handlerForDate} className={selectedPeriod === SelectedPeriod.THREE_MONTHS ? styles.activeDay : ''}>3 месяца</button>
                    <button name={SelectedPeriod.ALL_TIME} onClick={handlerForDate} className={selectedPeriod === SelectedPeriod.ALL_TIME ? styles.activeDay : ''}>за всё время</button>
                </div>
                <div className={styles.numbers}>
                    <div className={styles.numbersItem}>
                        <span>Количество заказов</span>
                        <FormatNumbers number={stats.count} />
                    </div>
                    <div className={styles.numbersItem}>
                        <span>Заказов на сумму</span>
                        <FormatNumbers number={`${stats.sum} ₽`} />
                    </div>
                    <div className={styles.numbersItem}>
                        <span>Средний чек</span>
                        <FormatNumbers number={`${stats.check} ₽`} />
                    </div>
                    <div className={styles.numbersItem}>
                        <span>Покупателей</span>
                        <FormatNumbers number={`${stats.buyers}`} />
                    </div>
                </div>
            </div>
            <div className={styles.ordersContainer}>
                <div className={styles.ordersHeader}>
                    <div className={styles.searchWrapper}>
                        <label htmlFor='search'>Найти заказ</label>
                        <input id='search' type="text" placeholder='введите номер заказа' onChange={handleSearchTextChange} />
                    </div>
                    <div className={styles.countOnPage}>
                        <span>Заказов на странице:</span>
                        <ul>
                            {countsForOrders.map((el) => (
                                <li
                                    key={el}
                                    className={itemsPerPage === el ? styles.activePage : null}
                                    onClick={() => {
                                        handleItemsPerPage(el)
                                        setActivePage(1);
                                    }}
                                >
                                    {el}
                                </li>
                            ))}

                        </ul>
                    </div>
                </div>
                <div className={styles.storage}>
                    <div className={styles.ordersInfo}>
                        <div>
                            <span>Дата и время заказа</span>
                            <SortButton onChange={(state) => {
                                setSortedState(state);
                                setSortedListName(FieldNameForSorting.CREATED_AT);
                            }}
                                sortingListName={FieldNameForSorting.CREATED_AT}
                                currentSortingListName={sortedListName}
                            />
                        </div>
                        <div>
                            <span>Номер заказа</span>
                        </div>
                        <div>
                            <span>Клиент</span>
                            <SortButton onChange={(state) => {
                                setSortedState(state);
                                setSortedListName(FieldNameForSorting.DELIVERY_CILENT_NAME);
                            }}
                                sortingListName={FieldNameForSorting.DELIVERY_CILENT_NAME}
                                currentSortingListName={sortedListName}
                            />
                        </div>
                        <div>
                            <span>Вид доставки</span>
                            <SortButton onChange={(state) => {
                                setSortedState(state);
                                setSortedListName(FieldNameForSorting.DELIVERY_TYPE);
                            }}
                                sortingListName={FieldNameForSorting.DELIVERY_TYPE}
                                currentSortingListName={sortedListName}
                            />
                        </div>
                        <div>
                            <span>Статус</span>
                        </div>
                        <div>
                            <span>Товары</span>
                        </div>
                        <div>
                            <span>Сумма заказа</span>
                            <SortButton onChange={(state) => {
                                setSortedState(state);
                                setSortedListName(FieldNameForSorting.TOTAL_AMOUNT);
                            }}
                                sortingListName={FieldNameForSorting.TOTAL_AMOUNT}
                                currentSortingListName={sortedListName}
                            />
                        </div>
                    </div>

                    {selectedByDateOrders.map((el, idx) => {
                        if (idx === Number(itemsPerPage) || idx >= Number(itemsPerPage)) {
                            return;
                        }
                        return <OrderItemComponent key={uuid()} order={el} />;

                    })}
                </div>
                <div className={styles.itemsCont}>
                    {selectedByDateOrders.map((el, idx) => {
                        if (idx === Number(itemsPerPage) || idx >= Number(itemsPerPage)) {
                            return;
                        }
                        return <OrderItemMobile key={uuid()} order={el} />;

                    })}
                </div>
                <div className={styles.pages}>
                    <span>Страница:</span>
                    <ul style={{ maxWidth: 500, display: "flex", flexWrap: "wrap" }}>
                        {buildPageNumbersRow(activePage, pagesCount, handlePageClick)}
                    </ul>
                </div>
            </div>
        </div>
    )
}
OrderListComponent.defaultProps = {
    orders: { count: 1152000, buyers: 100000, check: 3000, sum: 2000000 }
};
export default OrderListComponent;