import React, { useEffect, useState } from 'react';
import api from '../../api/api';
import Footer from '../../components/FooterComponent_v2';
import Header from '../../components/HeaderComponent_v2';
import NotificationComponent from '../../components/NotificationComponent';
import MainComponent from './components/MainComponent';

function HomePage2() {

    const [notificationText, setNotificationText] = useState('');
    const [notificationState, setNotificationState] = useState(false);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [notificationData, setNotificationData] = useState({});

    const [stats, setStats] = useState({
        name: '',
        products_count: 0,
        stores_count: 0,
        ordersStats: {},
        tariff: { products: 10, stores: 1, design_choice: false, name: '', renewal_date: '' },
        referral_code: null,
        wallet: 0
    });

    const makeOrdersStats = async (storesData) => {
        let totalOrders = 0;
        let orderAmount = 0;
        let totalCustomers = 0;
        let averageCheck = 0;

        for (const store of storesData) {
            try {
                const response = await api.getOrdersStatistics(store.id);
                const orderStatistics = response.data;

                totalOrders += orderStatistics.total_orders;
                orderAmount += orderStatistics.order_amount;
                totalCustomers += orderStatistics.total_customers;
                averageCheck += orderStatistics.average_check;
            } catch (error) {
                console.error(`Error fetching data for ID ${store.id}: ${error.message}`);
            }
        }

        const mergedResult = {
            total_orders: Number(totalOrders).toFixed(0),
            order_amount: Number(orderAmount / 100).toFixed(0),
            total_customers: Number(totalCustomers).toFixed(0),
            average_check: storesData.length > 0 ? Number(averageCheck / 100 / storesData.length).toFixed(0) : 0,
        };

        setStats((prevStats) => ({
            ...prevStats,
            ordersStats: mergedResult
        }))

    };
    useEffect(() => {
        api.getHomedata()
            .then((response) => {
                if (response.data) {
                    setStats(response.data);
                    setNotificationsCount(response.data.notifications_count);
                    setNotificationData(response.data.notification);

                }
            })
            .catch(error => { console.log(error) });

        api.getStoresList()
            .then((response) => {
                if (response.response) { if (response.response.data.code === "user_not_found") { localStorage.clear(); } }
                const storesData = response.data.stores.map((store) => { return { name: store.name, id: store.id } });
                makeOrdersStats(storesData);
            })
            .catch(error => { console.log(error) });


    }, []);

    useEffect(() => {
        if (notificationState) {
            const timer = setTimeout(() => {
                setNotificationState(false);
                setNotificationText("");
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [notificationState])

    return (
        <div>
            < Header title={"Главная"} />
            {notificationState ? <NotificationComponent text={notificationText} /> : null}
            <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                <MainComponent notificationsCount={notificationsCount} notificationData={notificationData} user={stats.name} activeShops={stats.stores_count} activeProducts={stats.products_count} ordersStats={stats.ordersStats} setInfo={setNotificationText} setIsInfo={setNotificationState} tariff={stats.tariff} referral={stats.referral} />
            </div>
            < Footer />
        </div>
    );
}

export default HomePage2;
