import React from "react";
import PropTypes from "prop-types";
import styles from "../styles/StarsComponent.module.css";
import yStar from "../assets/Star 5.png";
import wStart from "../assets/Star 6.png";

function StarRating({ rating }) {
  const fullStarCount = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;

  const renderStars = () => {
    const stars = [];

    for (let i = 1; i <= 5; i++) {
      if (i <= fullStarCount) {
        stars.push(
          <img key={i} src={yStar} className={styles.star} alt=""></img>
        );
      }  else {
        stars.push(
          <img key={i} className={styles.star} src={wStart} alt=""></img>
        );
      }
    }

    return stars;
  };

  return <div className={styles.starRating}>
    {renderStars()}
    <span>{rating + "/" + "5"}</span>
    </div>;
}

StarRating.propTypes = {
  rating: PropTypes.number.isRequired
};

export default StarRating;
