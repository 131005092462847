import React from "react";
import styles from './Button.module.css';

function Button({ secondary, onClick, text, disabled, type, id }) {
    return (
        <div className={styles.wrapper} id={id ? id : 'button'}>
            <button
                className={
                    `${secondary ? styles.buttonSecondary : styles.buttonPrimary}`
                }
                type={type ? type : 'button'}
                disabled={disabled}
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    onClick && onClick(event);
                }}>
                {text}
            </button>
        </div>
    )
}

export default Button;