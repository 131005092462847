import React from "react";
import styles from "../styles/CustomCheckBoxComponent.module.css";
import { v4 as uuid } from 'uuid';

function CustomCheckBox({ checked, onChange , required ,outId,name}) {
    const id = uuid();
    const inputId = `toCheckBox_${id}`; // Используйте uuid в качестве префикса для id

    return (
        <div>
            <input
                id={outId === undefined ? inputId:outId}
                type="checkbox"
                checked={checked}
                name={name}
                className={styles.base}
                onChange={onChange}
                required={required === undefined | required === false ? false : true}
            />
            <label htmlFor={outId === undefined ? inputId:outId} className={styles.custom}></label>
        </div>
    )
}

export default CustomCheckBox;