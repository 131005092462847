import React from "react";
import styles from "../styles/NotificationComponent.module.css";

function NotificationComponent({ text }) {
    return (
        <div className={styles.container}>
            <span className={styles.text}>
                {text}
            </span>
            {/* <span className={styles.href}>
                Посмотреть изменения
            </span> */}
        </div>
    )
}

NotificationComponent.defaultProps = {
    text: "Это уведомление которое поможет орентироваться по нашему сервису."
}
export default NotificationComponent;