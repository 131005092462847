import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ErrorMessage from '../../../components/ErrorMessage';
import styles from './TariffModal.module.css';
import api from '../../../api/api';

export default function TariffModal({ isOpen, content, onClose }) {
    const [agreePrivacyPolicy, setAgreePrivacyPolicy] = useState(true);
    const [agreeTariffs, setAgreeTariffs] = useState(true);

    const onContinue = () => {
        api.changeTariffv2(content.type).then(r => { window.location.href = r.data.pay_url; });
    };

    return (
        <div>
            {isOpen && (
                <div className={styles.modal}>
                    <div className={styles.popupContainer}>
                        <span className={styles.popupTitle}>Вы подключаете тариф "{content.name}" на месяц за {content.price} ₽</span>
                        <span className={styles.popupContent}>Деньги спишутся сразу после подключения. Через месяц за подписку будет списываться {content.old_price} ₽ в месяц, месяц — 30 дней</span>
                        <div className={styles.list}>
                            <div>
                                <input id="privacy" type="checkbox" checked={agreePrivacyPolicy} onChange={() => setAgreePrivacyPolicy(!agreePrivacyPolicy)} />
                                <label for="privacy">Вы даете <Link to={"https://docs.thebotique.ru/privacy-policy-seller-agreement"} target="_blank">согласие на обработку персональных данных</Link></label>
                            </div>
                            <div>
                                <input id="tariffs" type="checkbox" checked={agreeTariffs} onChange={() => setAgreeTariffs(!agreeTariffs)} />
                                <label for="tariffs">Вы ознакомлены и согласны с <Link to={"https://docs.thebotique.ru/license-agreement"} target="_blank"> офертой</Link>, <Link to={"https://docs.thebotique.ru/about-tariffs"} target="_blank"> периодичностью и размером списания, правилами отмены и возврата</Link></label>
                            </div>
                        </div>
                        {!agreeTariffs | !agreePrivacyPolicy ? (<ErrorMessage message={"Для оформления подписки Вам необходимо согласиться с условиями"} />) : (null)}
                        <div className={styles.popupFooter}>
                            <button className={styles.popupBtnBack} onClick={onClose}>Отменить</button>
                            <button id="standart-pay" className={styles.popupBtnPay} onClick={(e) => onContinue(e)}>К оплате</button>
                        </div>
                        <button className={styles.popupClose} onClick={onClose}></button>
                    </div>
                </div>
            )}
        </div>
    );
};
