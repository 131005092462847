import React, { useEffect, useRef, useState } from 'react';
import PinInput from 'react-pin-input';
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';
import logo from '../../assets/logo.svg';
import InfoMessage from '../../components/InfoMessage';
import Loading from '../../components/Loading';
import styles from './PinCodeVerification.module.css';

function PinCodeVerification() {
  const nav = useNavigate();
  const [pinCode, setPinCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isInfo, setIsInfo] = useState(false);
  const inputTarget = useRef(null);
  const handlePinCodeChange = (value) => {
    setPinCode(value);
  };

  const handleSubmit = (e, value) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    setIsLoading(true);

    api.pinConfirm(value === undefined ? pinCode : value)
      .then((response) => {
        setIsLoading(false);
        console.log(response);
        if (response.status === 200) {
          setPinCode('');
          nav('/login');
        }
        setIsInfo(true);
      })
  };
  useState(() => {
    if (inputTarget.current) {
      inputTarget.current.focus();
    }
  }, []);

  return (
    <div className={styles['pin-code-form']}>
      <div className={styles['top-content']}>
        <img src={logo} alt="Логотип" className={styles['logo']} />
        <h2>Введите код, отправленный на адрес электронной почты, который вы указали при регистрации</h2>
        <InfoMessage message={"Вы ввели неверный пин"} isVisible={isInfo} />
      </div>
      <form onSubmit={handleSubmit}>
        <PinInput
          length={6}
          initialValue={pinCode}
          ref={inputTarget}
          onChange={(value, index) => handlePinCodeChange(value)}
          type="numeric"
          inputMode="number"
          style={{ padding: '10px' }}
          inputStyle={{ background: "#d3d3d3", borderRadius: "3px", borderWidth: "0px", fontSize: "28px" }}
          inputFocusStyle={{ borderColor: 'blue' }}
          onComplete={(value, index) => {
            handlePinCodeChange(value);
            handleSubmit(undefined, value);
          }}
          autoSelect={true}
        />

        <div className={styles['button-container']}>
          <button type="submit" className={styles['pin-code-button']}>
            Далее
          </button>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Countdown seconds={60} />
            <a href='https://t.me/BotiqueSupport_bot?start'>Не приходит код?</a>
          </div>
        </div>
      </form>

      {isLoading ? <Loading /> : null}
    </div>
  );
}



const Countdown = ({ seconds }) => {
  const [time, setTime] = useState(seconds);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(intervalId);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div style={{ margin: 10, display: "flex", flexDirection: "column", alignItems: "flex-start", fontSize: 16, flexShrink: 0 }}>
      {/* Отправить повторно через: <span style={{ fontWeight: 600 }}>{formatTime(time)}</span> */}
      {/* {time === 0 ? <button style={{ flexShrink: 0, width: 100, fontSize: 10, padding: 5 }} className={styles['pin-code-button']}>Отправить повторно</button> : null} */}
    </div>
  );
};



export default PinCodeVerification;
