import { format } from 'date-fns';
import React, { useEffect, useState } from "react";
import api from '../../../api/api';
import gal from "../../../assets/check 1.png";
import ErrorMessage from "../../../components/ErrorMessage";
import PopupCustomComponent from '../../../components/PopupCustomComponent';
import TariffModal from './TariffModal';
import styles from "./TariffsBlock.module.css";


export default function TariffsBlock({ currentTariff, setCurrentTariff }) {

    const [pricing, setPricing] = useState({ standart: { old_price: 0, price: 0 }, individual: { old_price: 0, price: 0 } });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({ type: 1, price: 1999, old_price: 1999, name: 'Стандарт' });


    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const [standartText, setStandartText] = useState('Выбрать');
    const [individText, setIndividText] = useState('Выбрать');

    useEffect(() => {
        api.getPricing().then(r => { console.log(r.data); setPricing(r.data) });
    }, []);

    useEffect(() => {
        if (currentTariff.status === false) {
            if (currentTariff.type === 1) {
                setStandartText('Возобновить');
            } else if (currentTariff.type === 2) {
                setIndividText('Возобновить');
            }
        } else {
            if (currentTariff.type === 1) {
                setStandartText('Активный тариф');
            } else if (currentTariff.type === 2) {
                setIndividText('Активный тариф');
            }
        }

        if (currentTariff.type > 1 & currentTariff.status) {
            setStandartText('Недоступен');
        }
    }, [currentTariff]);

    const handleStandartChoice = () => {
        if (currentTariff.type < 2 & !currentTariff.status) {
            setModalContent(prev => ({ ...prev, type: 1, price: pricing.standart.price / 100, old_price: pricing.standart.old_price / 100, name: 'Стандарт' }));
            setIsModalOpen(true);
        } else if (currentTariff.type === 2 & !currentTariff.status) {
            setModalContent(prev => ({ ...prev, type: 1, price: pricing.standart.price / 100, old_price: pricing.standart.old_price / 100, name: 'Стандарт' }));
            setIsModalOpen(true);
        }
    };

    const handleIndividualChoice = () => {
        if (currentTariff.type < 2) {
            setModalContent(prev => ({ ...prev, type: 2, price: pricing.individual.price / 100, old_price: pricing.individual.old_price / 100, name: 'Индивидуальный' }));
            setIsModalOpen(true);
        } else if (currentTariff.type === 2 & !currentTariff.status) {
            setModalContent(prev => ({ ...prev, type: 2, price: pricing.individual.price / 100, old_price: pricing.individual.old_price / 100, name: 'Индивидуальный' }));
            setIsModalOpen(true);
        }
    };

    const cancelSubscription = () => {
        if (currentTariff.type > 0 & currentTariff.status === true) {
            api.cancelTariff().then((r) => {
                setIsError(true);
                setErrorText('Подписка отменена - тариф будет действовать до окончания оплаченного периода.');
                setCurrentTariff(prev => ({
                    ...prev,
                    status: false
                }))
            });
        }
    };

    return (
        <>
            <h1 className={styles.title}>Тарифные планы</h1>

            <div className={styles.tariffContainer}>
                <div className={styles.tarif}>

                    <h3 className={styles.tarifTitle}>Пробный</h3>

                    <div className={styles.tarifAdvantages}>
                        <div className={styles.advantage}>
                            <img src={gal} alt="" />
                            <span> 1 Telegram-магазин</span>
                        </div>
                        <div className={styles.advantage}>
                            <img src={gal} alt="" />
                            <span> Добавление 4 товаров</span>
                        </div>
                        <div className={styles.advantage}>
                            <img src={gal} alt="" />
                            <span> Простой дизайн магазина</span>
                        </div>
                        <div className={styles.advantage}>
                            <img src={gal} alt="" />
                            <span> Ограничение на получение 1 заказа</span>
                        </div>
                        <div className={styles.advantage}>
                            <img src={gal} alt="" />
                            <span> Базовая техподдержка</span>
                        </div>
                    </div>

                    <span className={styles.saleText}>Бесплатно</span>

                    <button className={currentTariff.type === 0 ? `${styles.freeButton} ${styles.activeTarif}` : styles.freeButton}> {currentTariff.type === 0 ? "Активный тариф" : "Недоступен"}</button>

                </div>

                <div className={styles.tarif}>
                    <h3 className={styles.tarifTitle}>Стандарт</h3>
                    <div className={styles.tarifAdvantages}>
                        <div className={styles.advantage}>
                            <img src={gal} alt="" />
                            <span> 1 Telegram-магазин</span>
                        </div>
                        <div className={styles.advantage}>
                            <img src={gal} alt="" />
                            <span> Добавление 200 товаров</span>
                        </div>
                        <div className={styles.advantage}>
                            <img src={gal} alt="" />
                            <span> 3 дизайна магазинов на выбор</span>
                        </div>
                        <div className={styles.advantage}>
                            <img src={gal} alt="" />
                            <span> <b>Неограниченное количество заказов</b></span>
                        </div>
                        <div className={styles.advantage}>
                            <img src={gal} alt="" />
                            <span> Базовая техподдержка</span>
                        </div>
                    </div>
                    {pricing.standart.old_price !== pricing.standart.price && < span className={styles.oldSalePrice}>{pricing.standart.old_price / 100} руб. / месяц</span>}
                    <span className={styles.saleText}>{pricing.standart.price / 100} руб. / месяц</span>

                    <button className={currentTariff.type === 1 ? `${styles.freeButton} ${styles.activeTarif}` : styles.popupBtnBack} onClick={handleStandartChoice}>{standartText}</button>

                </div>

                <div className={styles.tarif}>
                    <h3 className={styles.tarifTitle}>Индивидуальный</h3>
                    <div className={styles.tarifAdvantages}>
                        <div className={styles.advantage}>
                            <img src={gal} alt="" />
                            <span> Индивидуальный дизайн магазина или выбор готового</span>
                        </div>
                        <div className={styles.advantage}>
                            <img src={gal} alt="" />
                            <span> Персональный менеджер поддержки с полноценным сопровждением</span>
                        </div>
                        <div className={styles.advantage}>
                            <img src={gal} alt="" />
                            <span> Доступ к списку посетителей и аналитике магазина</span>
                        </div>
                        <div className={styles.advantage}>
                            <img src={gal} alt="" />
                            <span> Таргетированные рекламные рассылки для покупателей</span>
                        </div>
                        {/* <div className={styles.advantage}>
                            <img src={gal} alt="" />
                            <span> <b>Участие в программе «маркетплейс Botique»</b></span>
                        </div> */}
                    </div>

                    {pricing.individual.old_price !== pricing.individual.price && < span className={styles.oldSalePrice}>{pricing.individual.old_price / 100} руб. / месяц</span>}
                    <span className={styles.saleText}>{pricing.individual.price / 100} руб. / месяц</span>

                    <button className={currentTariff.type === 2 ? `${styles.freeButton} ${styles.activeTarif}` : styles.popupBtnBack} onClick={handleIndividualChoice}>{individText}</button>
                </div>
            </div >

            {/* <span className={styles.sale}>* ограниченное предложение</span> */}
            {
                currentTariff.type > 0 & currentTariff.status ? (
                    <span className={styles.cancel}>{`Следующая дата списания: ${format(new Date(currentTariff.renew_date), 'dd.MM.yyyy')}. `}Также вы можете
                        <PopupCustomComponent triggerEl={<span className={styles.cancelBtn}>отменить подписку</span>} title={"Вы действительно хотите отменить подписку?"} text={"Действие тарифа не прекращается до окончания оплаченного периода."} handler={cancelSubscription} />.
                    </span >
                ) : null
            }
            {isError ? (<ErrorMessage message={errorText} />) : (null)}

            <TariffModal isOpen={isModalOpen} content={modalContent} onClose={() => { setIsModalOpen(false); }} />
        </>
    );
}