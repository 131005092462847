import PopupCustomComponent from '../../../../../components/PopupCustomComponent';
import { removeCard } from '../../../handlers/handlers';
import styles from './VariationsCardsList.module.css';
export const VariationsCardList = ({ combinations, setChoosenCombination, choosenCombination, productData, setProductData }) => {

    // const [combinationsList, setCombinationsList] = useState(combinations);

    return (
        <div className={styles.wrapper}>
            {combinations.map((el, idx) => {
                return (
                    <div key={el.id} className={`${styles.card} ${choosenCombination === idx ? styles.activeCard : ''}`} onClick={() => { setChoosenCombination(idx) }}>
                        <span>{el.var1_value}</span>
                        <span>{el.var2_value}</span>
                        <PopupCustomComponent key={idx} triggerEl={<button title="Удалить" type="button" />} title={"Вы действительно хотите удалить вариацию?"} text={"После удаления восстановить вариацию не получится"} handler={() => removeCard(idx, productData, setProductData)} />
                    </div>
                )
            })}
        </div>
    );
}