import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import blueOne from "../../../assets/icon add-2.png";
import plus from "../../../assets/icon add-3.png";
import BannerItemComponent from "./BannerItemComponent_v2";
import styles from "./BannersComponent_v2.module.css";

function BannersComponent({ banners, handlerDelete }) {

    const navigator = useNavigate();

    const [plusImage, setPlusImage] = useState(plus);
    const handlerForPlus = () => {
        setPlusImage(blueOne);
    };

    const handlerForPlusBack = () => {
        setPlusImage(plus);
    };

    const handlerForAdd = () => {
        navigator('/create-banner');
    };

    useEffect(() => {
        document.body.classList.add(styles.bodyBlock);

        return () => {
            document.body.classList.remove(styles.bodyBlock);
        };
    }, []);


    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Список баннеров/акций</h1>
            <div className={styles.banners}>
                {banners.map(el => <BannerItemComponent key={uuid()} name={el.name} status={el.state} isImage={el.text === ''} textOrImage={el.img} text={el.text} start={el.start_date} end={el.end_date} id={el.id} handlerDelete={handlerDelete} />)}
                <div onClick={handlerForAdd} className={styles.addBanner} onMouseEnter={handlerForPlus} onMouseLeave={handlerForPlusBack}>
                    <img src={plusImage} alt=""></img>
                    <span>Добавить новую баннер/акцию</span>
                </div>
            </div>
        </div>
    )
}
export default BannersComponent;