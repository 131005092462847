import React, { useEffect, useState } from 'react';
import api from '../api/api';
import styles from './ProductGroup.module.css';
import { error, success } from '../../../components/notification';

const ProductGroup = ({ groupID, groupName, productsCount, labelOptions, deleteGroup, addProducts, products, setProducts, labels, isExpanded, toggleExpand, isToggleLoading }) => {
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [labelText, setLabelText] = useState('');
    const [labelID, setLabelID] = useState('');
    const [labelStyle, setLabelStyle] = useState(labelOptions[0]);

    const handleProductClick = (productId) => {
        if (selectedProducts.includes(productId)) {
            setSelectedProducts(selectedProducts.filter(id => id !== productId));
        } else {
            setSelectedProducts([...selectedProducts, productId]);
        }
    };

    const handleSelectAll = () => {
        setSelectedProducts(products.map(product => product.id));
    };

    const handleDeselectAll = () => {
        setSelectedProducts([]);
    };

    const handleRemoveSelected = () => {
        api.removeProductsFromGroup(groupID, selectedProducts).then(r => {
            const updatedProducts = products.filter(product => !selectedProducts.includes(product.id));
            setProducts(updatedProducts);
            setSelectedProducts([]);
        });
    };

    const handleLabelTextChange = (e) => {
        if (e.target.value.length <= 10)
            setLabelText(e.target.value);
    };

    const handleLabelStyleChange = (e) => {
        const selectedStyle = labelOptions.find(option => option.name === e.target.value);
        setLabelStyle(selectedStyle);
    };

    const truncateName = (name) => {
        return name.length > 48 ? `${name.substring(0, 48)}...` : name;
    };

    const getSuffix = (count) => {
        if (count === 1) {
            return 'товар';
        } else if (count < 5) {
            return 'товара';
        } else {
            return 'товаров';
        }
    };

    const deleteThisGroup = () => {
        deleteGroup(groupID);
    };

    const handleUpdateLabel = () => {
        api.updateGroupLabel(groupID, {
            style_id: labelStyle.id,
            text: labelText,
            label_id: labelID
        }).then(r => {
            if (r?.data) {
                success('Данные обновлены.');
            } else {
                error('Произошла ошибка.');
                setLabelText('');
            }
        });
    };

    useEffect(() => {
        if (labels.length > 0) {
            setLabelStyle(labelOptions.filter(option => option.id === labels[0].style.id)[0]);
            setLabelText(labels[0].text);
            setLabelID(labels[0].id);
        } else {
            setLabelStyle(labelOptions[0]);
            setLabelText('');
            setLabelID('');
        }
    }, [labels]);

    return (
        <div className={styles.productGroup}>
            <div
                className={`${styles.groupHeader} ${isExpanded ? styles.expanded : ''}`}
                onClick={() => toggleExpand(groupID)}
            >
                <span>{groupName} ({productsCount} {getSuffix(productsCount)})</span>
                <button className={styles.toggleButton}>
                    {isToggleLoading ? <div className={styles.loader} /> : isExpanded ? '-' : '+'}
                </button>
            </div>

            {isExpanded && (
                <div className={styles.groupContent}>
                    <div className={styles.actions}>
                        {selectedProducts.length < products.length && <button onClick={handleSelectAll}>Выделить все</button>}
                        {selectedProducts.length === products.length && <button onClick={handleDeselectAll}>Снять выделение</button>}
                        {selectedProducts.length > 0 && <button onClick={handleRemoveSelected}>Удалить выбранные</button>}
                    </div>
                    <div>
                        <div className={styles.productList}>

                            {products.length > 0 && products.map(product => (
                                <div
                                    key={product.id}
                                    className={`${styles.productItem} ${selectedProducts.includes(product.id) ? styles.selected : ''}`}
                                    onClick={() => handleProductClick(product.id)}
                                >
                                    <img src={product.image} alt={product.name} />
                                    <p>{truncateName(product.name)}</p>
                                </div>
                            ))}
                            <button className={styles.addButton} onClick={() => addProducts(groupID, products)}>+</button>
                        </div>
                    </div>
                    <div className={styles.labelSection}>
                        <h2>Добавление Лейбла</h2>
                        <div className={styles.labelManagement}>
                            <div className={styles.labelText}>
                                Текст:
                                <input
                                    type="text"
                                    placeholder='Текст'
                                    value={labelText}
                                    onChange={handleLabelTextChange}
                                    className={styles.labelInput}
                                />
                            </div>
                            <div className={styles.labelStyle}>
                                Стиль:
                                <select
                                    value={labelStyle.name}
                                    onChange={handleLabelStyleChange}
                                    className={styles.labelSelect}
                                >
                                    {labelOptions.map(option => (
                                        <option key={option.name} value={option.name}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {labelText !== '' && <div className={styles.labelPreviewBlock}>
                                Предпросмотр:
                                <div
                                    className={styles.labelPreview}
                                    style={{
                                        backgroundColor: labelStyle.label_color,
                                        color: labelStyle.text_color,
                                        borderColor: labelStyle.border_color,
                                        borderStyle: labelStyle.border_style,
                                        borderWidth: labelStyle.border_width,
                                    }}
                                >
                                    {labelText}
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className={styles.buttons}>
                        <button onClick={handleUpdateLabel}>Сохранить</button>
                        <button onClick={deleteThisGroup}>Удалить</button>
                    </div>
                </div>
            )
            }
        </div >
    );
};

export default ProductGroup;
