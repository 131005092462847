import React, { useState } from 'react';
import Footer from '../../components/FooterComponent_v2';
import Header from '../../components/HeaderComponent_v2';
import Design from './components/DesignComponent_v2';
import NotificationComponent from '../../components/NotificationComponent';

const DesignChoicePage = () => {
    const [notificationText] = useState('');
    const [notificationState] = useState(false);
    return (
        <div>
            <Header title={"Выбор оформления магазина"} />
            <Design />
            {notificationState ? <NotificationComponent text={notificationText} /> : null}
            <Footer />
        </div>
    );
};

export default DesignChoicePage;
