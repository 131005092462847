import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../../api/api";
import arrow from '../../../assets/Vector.png';
import plus from '../../../assets/icon add-3.png';
import banner from '../../../assets/image 20.png';
import Loading from "../../../components/Loading";
import NotificationComponent from "../../../components/NotificationComponent";
import { error, success } from "../../../components/notification";
import styles from './ActionComponent_v2.module.css';

function Action() {
    const navigator = useNavigate();
    const { bannerID } = useParams();
    const [bannerStatus, setBannerStatus] = useState('inactive');
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useRef(null);

    const initalDate = new Date();

    const formatDate = (date) => {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }

    const [formData, setFormData] = useState({
        name: '',
        text: '',
        image: '',
        status: false,
        sort: '',
        start_date: formatDate(initalDate),
        end_date: formatDate(initalDate),
        product_id: '',
    });

    const handleImageChange = (e) => {
        if (inputRef) {
            const file = inputRef.current.files[0];
            setSelectedFile(file);
            try {
                setSelectedPhoto(URL.createObjectURL(file));
            } catch { }
        }
    };


    const handlerForStatus = (e) => {
        for (let el of e.currentTarget.parentElement.children) {
            el.classList.remove(styles.activeBtn);
        }
        e.currentTarget.classList.add(styles.activeBtn);
        if (e.target.name === 'set-active') {
            setBannerStatus('active');
        } else {
            setBannerStatus('inactive');
        }
    };

    const updateStartDate = (date) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            start_date: formatDate(date),
        }));
    };

    const updateEndDate = (date) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            end_date: formatDate(date),
        }));
    };

    function formatDateToISO(dateString) {
        const parts = dateString.split('-');

        const year = parts[0];
        const month = parts[1].padStart(2, '0');
        const day = parts[2].padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const handleBannerDelete = () => {
        if (bannerID !== "new" & bannerID !== undefined) {
            api.deleteBanner(bannerID).then((response) => {
                if (response.code === "ERR_BAD_REQUEST") {
                    error('При удалении баннера произошла ошибка');
                } else {
                    navigator('/banners');
                }
            }).catch(error => { console.log(error) });
        } else {
            navigator('/banners');
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const newData = formData;
        newData['name'] = formData['name'] === '' ? 'Без названия' : formData['name'];
        newData['sort'] = formData['sort'] === '' ? 1 : formData['sort'];

        newData['state'] = bannerStatus === 'active';
        if (bannerID !== "new" & bannerID !== undefined) {
            setIsLoading(true);
            api.updateBanner(newData, bannerID).then((response) => {
                if (response.code === "ERR_BAD_REQUEST") {
                    error("Ошибка: Проверьте введенные данные и попробуйте снова");
                    setIsLoading(false);
                } else {
                    if (selectedPhoto) {
                        const task = `{"request_id": "f80688de", "expires": "2032-07-12T14:14:18Z", "operations": [ { "export": [{ "bucket": "botique", "key": "banners/${bannerID}/1" }] } ] }`;
                        var formdata = new FormData();
                        formdata.append("task", task);
                        formdata.append("uploadFile", selectedPhoto);
                        api.uploadImg(formdata).then((response) => {
                            setIsLoading(false);
                        }).catch((error) => { console.log(error) })
                    }
                    setIsLoading(false);
                    success('Данные баннера обновлены!');
                }
            })
                .catch(error => { console.log(error) });
        }
        else {
            setIsLoading(true);
            api.createBanner(newData).then((response) => {
                if (response.code === "ERR_BAD_REQUEST") {
                    error("При сохранении произошла ошибка");
                    setIsLoading(false);
                } else {
                    if (selectedPhoto) {
                        const task = `{"request_id": "f80688de", "expires": "2032-07-12T14:14:18Z", "operations": [ { "export": [{ "bucket": "botique", "key": "banners/${response.data.id}/1" }] } ] }`;
                        var formdata = new FormData();
                        formdata.append("task", task);
                        formdata.append("uploadFile", selectedFile);
                        api.uploadImg(formdata).then((response) => {
                            setIsLoading(false);
                            success('Баннер успешно создан!');
                            setTimeout(() => { window.location.href = '/banners'; }, 100);
                        }).catch((error) => {
                            error("При сохранении  произошла ошибка");
                        });
                    } else {
                        error("Вы не загрузили фото для баннера");
                        setTimeout(() => { window.location.href = '/banners'; }, 100);
                    }
                }
            })
                .catch(error => { console.log(error) });
        }
    };

    useEffect(() => {
        if (bannerID !== undefined) {
            if (bannerID !== "new") {
                api.getBanner(bannerID)
                    .then((response) => {
                        if (response.code === "ERR_BAD_REQUEST") {
                            error("При загрузке баннера произошла ошибка. Если ошибка сохранятеся - свяжитесь с поддержкой");
                        } else {
                            setFormData(response.data);
                            setBannerStatus(response.data.state ? 'active' : 'inactive');
                            setSelectedPhoto(`https://storage.yandexcloud.net/botique/banners/${bannerID}/1?t=${Date.now()}`)
                        }
                    })
                    .catch(error => { console.log(error) });
            }
        }
    }, [bannerID]);
    useEffect(() => {
        document.body.classList.add(styles.bodyBlock);

        return () => {
            document.body.classList.remove(styles.bodyBlock);
        };
    }, []);
    const [notificationText] = useState('');
    const [notificationState] = useState(false);
    return (
        <form className={styles.container} onSubmit={handleSubmit}>
            <Link to='/banners' className={styles.backBtn}>
                <img src={arrow} alt="" />
                <span>Вернуться ко всем баннерам/акциям</span>
            </Link>
            {notificationState ? <NotificationComponent text={notificationText} /> : null}
            <h1 className={styles.title}>Данные акции</h1>
            <div className={styles.formContainer} onSubmit={handleSubmit}>
                <div className={styles.formColumn}>
                    <div className={styles.formContent}>
                        <div>
                            <label className={styles.chooseImg} htmlFor="fileInput">
                                {selectedPhoto === null ? <div className={styles.emptyInput}>
                                    <img src={plus} alt=""></img>
                                    <span>Добавить новое изображение</span>
                                </div> :
                                    <img className={styles.choosenImg} src={selectedPhoto} alt="Изображение" />}
                            </label>
                            <input type="file" ref={inputRef} id="fileInput" onChange={handleImageChange} style={{ display: "none" }} />
                        </div>
                        <div className={styles.formGroup}>
                            <span>Текст акции (отображается на баннере)</span>
                            <input
                                type="text"
                                placeholder="Например, Бесплатная доставка от 2000 ₽"
                                name="text"
                                value={formData.text}
                                onChange={(e) => {
                                    if (e.target.value.length > 512) {
                                        error('Введите не более 512 символов');
                                        return;
                                    }
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        text: e.target.value,
                                    }));
                                }}
                                required>
                            </input>
                        </div>
                    </div>
                    <div className={styles.formContent}>
                        <div className={styles.example}>
                            <div className={styles.number1}>1</div>
                            <div className={styles.number2}>2</div>
                            <img src={banner} alt="" className={styles.exampleImg} />
                        </div>
                        <div className={styles.formGroup}>
                            <span>Пример отображения в <br></br>приложении:</span>
                            <div className={styles.numbers}>
                                <div className={styles.number}>
                                    1
                                </div>
                                <span>Загруженная иконка</span>
                            </div>
                            <div className={styles.numbers}>
                                <div className={styles.number}>
                                    2
                                </div>
                                <span>Текст акции</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.formColumn}>
                    <div className={styles.inputs}>
                        <div className={styles.inputsColumn}>
                            <div className={styles.formGroup}>
                                <span>Название акции</span>
                                <input
                                    type="text"
                                    placeholder="Например, бесплатная доставка"
                                    name="name"
                                    value={formData.name}
                                    onChange={(e) => {
                                        if (e.target.value.length > 128) {
                                            error('Введите не более 128 символов');
                                            return;
                                        }
                                        setFormData((prevFormData) => ({
                                            ...prevFormData,
                                            name: e.target.value,
                                        }));
                                    }}>
                                </input>
                            </div>
                            <div className={styles.formGroup}>
                                <span>Приоритет</span>
                                <input
                                    type="text"
                                    placeholder="Введите число, например, 1"
                                    name="sort"
                                    value={formData.sort}
                                    onChange={(e) => {
                                        if (isNaN(e.target.value)) {
                                            error('Введите число');
                                            return;
                                        }
                                        setFormData((prevFormData) => ({
                                            ...prevFormData,
                                            sort: e.target.value,
                                        }));
                                    }}>
                                </input>
                            </div>
                        </div>
                        <div className={styles.inputsColumn}>
                            <div className={styles.formGroup}>
                                <span>Дата начала</span>
                                <DatePicker
                                    selected={new Date(formatDateToISO(formData.start_date))}
                                    selectsStart
                                    startDate={new Date(formatDateToISO(formData.start_date))}
                                    endDate={new Date(formatDateToISO(formData.end_date))}
                                    onChange={date => updateStartDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                            <div className={styles.formGroup}>
                                <span>Дата окончания</span>
                                <DatePicker
                                    selected={new Date(formatDateToISO(formData.end_date))}
                                    selectsEnd
                                    startDate={new Date(formatDateToISO(formData.start_date))}
                                    endDate={new Date(formatDateToISO(formData.end_date))}
                                    minDate={new Date(formatDateToISO(formData.start_date))}
                                    onChange={date => updateEndDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>

                    </div>
                    <div className={styles.actionStatus}>
                        <span>Статус акции:</span>
                        <div className={styles.statusBtns}>
                            <button name='set-active' onClick={handlerForStatus} type="button" className={bannerStatus === 'active' ? `${styles.activeBtn}` : null}>Активна</button>
                            <button name='set-inactive' onClick={handlerForStatus} type="button" className={bannerStatus === 'inactive' ? `${styles.activeBtn}` : null}>Неактивна</button>
                        </div>
                    </div>
                </div>
                {isLoading && <Loading />}
            </div>
            <div className={styles.saveBtns}>
                <button>Сохранить</button>
                <button onClick={handleBannerDelete} type="button">Удалить</button>
            </div>
        </form>
    )
}
export default Action;