import { format } from 'date-fns';
import React,{useEffect} from 'react';
import api from '../../../api/api';
import styles from "./ToolTipComponent.module.css";

const Tooltips = ({ notifications, setNotifications }) => {

  const handleNotificationClick = (id) => {
    const updatedNotifications = notifications.map((notification) =>
      notification.id === id ? { ...notification, is_seen: true } : notification
    );
    api.changeNotificationsData({ id: id, is_seen: true })
      .then((r) => {
        setNotifications(updatedNotifications);
      })
      .catch((e) => { })

  };;


  useEffect(() => {
    document.body.classList.add(styles.bodyBlock);

    return () => {
      document.body.classList.remove(styles.bodyBlock);
    };
  }, []);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Уведомления</h1>
      <div className={styles.list}>

      </div>
      {notifications.map((notification) => (
        <div
          key={notification.id}
          onClick={() => handleNotificationClick(notification.id)}
          className={styles.toolTip}
        >
          <span className={styles.datetime}>{format(new Date(notification.created_at), 'dd-MM-yyyy HH:mm')}</span>
          <span>{notification.title}</span>
          <div dangerouslySetInnerHTML={{ __html: notification.content }} />
          {notification.is_seen ? (<span className={styles.seenTag}>Просмотренно</span>) : null}
        </div>
      ))}
    </div>
  );
};



export default Tooltips;
