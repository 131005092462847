import axios from "axios";
const productBaseUrl = 'https://api-admin.thebotique.ru/api/v1/products';
// const productBaseUrl = 'http://127.0.0.1:8000/api/v1/products';
const baseAPI = 'https://api-admin.thebotique.ru/api/v1';
// const baseAPI = 'http://127.0.0.1:8000/api/v1';
const basePhotoAPIURL = "https://render-api.thebotique.ru";

export const productAPI = {
    // Получение мультикарточки товара
    get: async (productID) => {
        try {
            const response = await axios.get(`${productBaseUrl}/${productID}/`, {
                params: { token: localStorage.getItem("_signiture_for_store_data") },
                headers: { Authorization: "Bearer " + localStorage.getItem("access_token") }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    // Создание мультикарточки товара
    create: async (productData) => {
        try {
            const response = await axios.post(`${productBaseUrl}/new/`,
                { ...productData, token: localStorage.getItem("_signiture_for_store_data") },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access_token"),
                    }
                });
            return response;
        } catch (error) {
            throw error;
        }
    },
    // Обновление мультикарточки товара
    update: async (productID, productData) => {
        try {
            const response = await axios.put(`${productBaseUrl}/${productID}/`,
                { ...productData, token: localStorage.getItem("_signiture_for_store_data") },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access_token"),
                    }
                });
            return response;
        } catch (error) {
            throw error;
        }
    },
    // Удаление мультикарточки товара
    delete: async (productID) => {
        try {
            const response = await axios.delete(`${productBaseUrl}/${productID}/`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    // Удаление карточки товара (комбо)
    deleteCard: async (comboID) => {
        try {
            const response = await axios.delete(`${productBaseUrl}/combo-delete/${comboID}/`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
};

export const fixedCharacteristicsAPI = {
    // Создание фиксированной характеристики для мультикарточки товара
    create: async (characteristicData) => {
        try {
            const response = await axios.post(`${productBaseUrl}/characteristic-new/`,
                { ...characteristicData, token: localStorage.getItem("_signiture_for_store_data") },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access_token"),
                    }
                });
            return response;
        } catch (error) {
            throw error;
        }
    },
    // Удаление фиксированной характеристики из мультикарточки товара
    delete: async (fixedCharacteristicID) => {
        try {
            const response = await axios.delete(`${productBaseUrl}/characteristic-delete/${fixedCharacteristicID}/`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
};

export const productPhotosAPI = {
    // привязка фотографии к карточке товара
    create: async (photoData) => {
        try {
            const response = await axios.post(`${productBaseUrl}/photo-new/`, photoData, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    // Удаление фотографии из карточки товара
    delete: async (photoID) => {
        try {
            const response = axios.delete(`${productBaseUrl}/photo-delete/${photoID}/`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    // Загрузка фото в яндекс облако
    uploadImg: (formData) => {
        try {
            const response = axios.post(`${basePhotoAPIURL}/api/v1/media/upload`, formData, {
                headers: {
                    "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
                },
            })
            return response;
        } catch (error) {
            throw error;
        }
    },
    // Оптимизация изображения
    uploadAndOptimizeImg: (formData) => {
        try {
            const response = axios.post(`${baseAPI}/photo-upload/`, formData, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                    "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
                },
            })
            return response;
        } catch (error) {
            throw error;
        }
    },
};

export const digitalAPI = {
    // Получение типа товаров магазина
    checkDigital: async () => {
        try {
            const response = await axios.get(`${productBaseUrl}/check-digital`, {
                params: { token: localStorage.getItem("_signiture_for_store_data"), store_id: localStorage.getItem("current_store_id") },
                headers: { Authorization: "Bearer " + localStorage.getItem("access_token") }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    // Цифровые категории
    getDigitalCategories: async () => {
        try {
            const response = await axios.get(`${baseAPI}/categories/list`, {
                params: { digital: true },
                headers: { Authorization: "Bearer " + localStorage.getItem("access_token") }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
}


export const aiAPI = {
    // Генерация описания по названию
    generateDescription: async (title) => {
        try {
            const response = await axios.post(`${baseAPI}/generate-title/`, { title: title }, {
                headers: { Authorization: "Bearer " + localStorage.getItem("access_token") }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
}