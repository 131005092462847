import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../api/api';
import logo from "../../assets/logo.svg";
import ErrorMessage from '../../components/ErrorMessage';
import Loading from '../../components/Loading';
import styles from './LoginPage.module.css';

const ResetPage = () => {
    const nav = useNavigate();
    const [username, setUsername] = useState('');

    const [errorText, setErrorText] = useState('');
    const [isError, setIsError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const inputTarget = useRef(null);


    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsLoading(true);

        api.sendRecoveryEmail(username)
            .then((response) => {
                console.log(response.data)
                setIsLoading(false);

                setErrorText(`Инструкция отправлена на почтовый адрес, указанный при регистрации`)
                setIsError(true);
                const timer = setTimeout(() => {
                    nav('/login');
                }, 5000);
                return () => clearTimeout(timer);
            })
            .catch(error => {
                setIsLoading(false);
                setErrorText("К сожалению произошла ошибка, попробуйте снова позже.");
                setIsError(!isError);
            });
    };

    useEffect(() => {
        document.title = "Авторизация"
        inputTarget.current.focus();
        document.body.classList.add(styles.bodyBlock);

        return () => {
            document.body.classList.remove(styles.bodyBlock);
        };
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.loginPagePreview}>
                <div className={styles.loginPagePreviewHeader}>
                    <h4>Добро пожаловать на платформу BOTIQUE!</h4>
                    <p>Botique - это веб-сервис для создания “умных” телеграмм магазинов с готовым сервисом для проведения онлайн-оплаты.</p>
                </div>
                <div className={styles.loginPagePreviewFooter}>
                    <img src={logo} alt=''></img>
                    <span>2023-{new Date().getFullYear()} © Все права защищены</span>
                    <Link to={'https://docs.thebotique.ru/license-agreement'} target="_blank">Лицензионный договор</Link>
                    <Link to={'https://docs.thebotique.ru/terms-of-use'} target="_blank">Правила использования cервиса</Link>
                    <Link to={'https://docs.thebotique.ru/privacy-policy'} target="_blank">Политика обработки персональных данных</Link>
                </div>
            </div>
            <div className={styles.loginPageContainer}>
                <div className={styles.loginPage}>
                    <h4>Восстановление пароля</h4>
                    <form onSubmit={handleSubmit} >
                        <div className={styles.formGroup}>
                            <input ref={inputTarget} type="text" placeholder='Имя пользователя или email' required onChange={e => setUsername(e.target.value)} className={styles.formInput} />
                        </div>

                        {isError && <div className={styles.buttonGroup}><ErrorMessage message={errorText} /></div>}

                        <div className={styles.buttonGroup}>
                            <button className={styles.loginButton}>Восстановить</button>
                        </div>
                    </form>

                    <div className={styles.fastLinks}>
                        <p className={styles.registerLink}>Нет аккаунта? <Link to={'/register'}>Зарегистрироваться</Link></p>
                        <p className={styles.registerLink}>Уже есть аккаунт? <Link to={'/login'}>Войти</Link></p>
                    </div>
                </div>
            </div>
            {isLoading ? <Loading /> : null}
        </div >
    );
};

export default ResetPage;

