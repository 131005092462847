import React, { useEffect, useRef, useState } from 'react';
import styles from "../styles/DropDownForFormComponent.module.css";

const DropdownForForm = ({ options, placeHolder, initialChoice, setChoice, isAllowed }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState('');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    if (!isAllowed) {
      setSelectedOption(option.name);
      setIsOpen(false);
      setChoice(option.id);
    } else if (isAllowed(option.id)) {
      setSelectedOption(option.name);
      setIsOpen(false);
      setChoice(option.id);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (initialChoice && options.length > 0) {
      const foundItem = options.find((item) => { return item.id === initialChoice });
      if (foundItem) {
        setSelectedOption(foundItem.name);
      } else { }
    }
  }, [initialChoice, options])

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <button className={styles.dropdownToggle} onClick={toggleDropdown} type="button">
        {selectedOption ? selectedOption : placeHolder}
      </button>
      {isOpen && (
        <ul className={styles.dropdownMenu}>
          {options.map((option, index) => (
            <li key={index} onClick={() => selectOption(option)}>
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownForForm;
