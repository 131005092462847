import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Footer from '../../components/FooterComponent_v2';
import Header from '../../components/HeaderComponent_v2';
import NewBanner from './components/NewBannerComponent_v2';

export default function NewBannerPage() {
    return (
        <div>
            <Header title={"Форма баннера"} />
            <NewBanner />
            <Footer />
        </div>
    );
}