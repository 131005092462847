import axios from "axios";

const baseAPIURL = "https://api-admin.thebotique.ru"
// const baseAPIURL = "http://127.0.0.1:8000"

const botSettingsAPI = {
    getBotSettings: async (storeID) => {
        try {
            return await axios.get(`${baseAPIURL}/api/v1/stores/store/${storeID}/bot-settings`, {
                params: { token: localStorage.getItem("_signiture_for_store_data") },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            });
        } catch (error) {
            throw error;
        }
    },
    updateBotSettings: async (storeID, data) => {
        try {
            return await axios.put(`${baseAPIURL}/api/v1/stores/store/${storeID}/bot-settings/`, { ...data, token: localStorage.getItem("_signiture_for_store_data") }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            });
        } catch (error) {
            throw error;
        }
    },
    getOrderFormSettings: async (storeID) => {
        try {
            return await axios.get(`${baseAPIURL}/api/v1/stores/store/${storeID}/order-form-settings`, {
                params: { token: localStorage.getItem("_signiture_for_store_data") },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            });
        } catch (error) {
            throw error;
        }
    },
    updateOrderFormSettings: async (storeID, data) => {
        try {
            return await axios.put(`${baseAPIURL}/api/v1/stores/store/${storeID}/order-form-settings/`, { ...data, token: localStorage.getItem("_signiture_for_store_data") }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            });
        } catch (error) {
            throw error;
        }
    },
    getMinOrderPrice: async (storeID) => {
        try {
            return await axios.get(`${baseAPIURL}/api/v1/stores/store/${storeID}/min-order-price`, {
                params: { token: localStorage.getItem("_signiture_for_store_data") },
                headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
            });
        } catch (error) {
            throw error;
        }
    },
    updateMinOrderPrice: async (storeID, data) => {
        try {
            return await axios.put(`${baseAPIURL}/api/v1/stores/store/${storeID}/min-order-price/`, { ...data, token: localStorage.getItem("_signiture_for_store_data") }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            });
        } catch (error) {
            throw error;
        }
    },
    getPaymentMessage: async (storeID) => {
        try {
            return await axios.get(`${baseAPIURL}/api/v1/stores/store/${storeID}/payment-message`, {
                params: { token: localStorage.getItem("_signiture_for_store_data") },
                headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
            });
        } catch (error) {
            throw error;
        }
    },
    updatePaymentMessage: async (storeID, data) => {
        try {
            return await axios.put(`${baseAPIURL}/api/v1/stores/store/${storeID}/payment-message/`, { ...data, token: localStorage.getItem("_signiture_for_store_data") }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            });
        } catch (error) {
            throw error;
        }
    },
    getFreeDeliveryPrice: async (storeID) => {
        try {
            return await axios.get(`${baseAPIURL}/api/v1/stores/store/${storeID}/free-delivery-price`, {
                params: { token: localStorage.getItem("_signiture_for_store_data") },
                headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
            });
        } catch (error) {
            throw error;
        }
    },
    updateFreeDeliveryPrice: async (storeID, data) => {
        try {
            return await axios.put(`${baseAPIURL}/api/v1/stores/store/${storeID}/free-delivery-price/`, { ...data, token: localStorage.getItem("_signiture_for_store_data") }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            });
        } catch (error) {
            throw error;
        }
    },
}
export default botSettingsAPI;