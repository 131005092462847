import axios from "axios";

// const baseAPIURL = 'http://localhost:8000';
const baseAPIURL = "https://api-admin.thebotique.ru";

const api = {
    // Получение списка всех товаров
    getProducts: async () => {
        try {
            const response = await axios.get(`${baseAPIURL}/api/v1/products/mini-list`,
                {
                    params: {
                        store_id: localStorage.getItem("current_store_id"),
                        token: localStorage.getItem("_signiture_for_store_data"),
                    },
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access_token"),
                    },
                }
            )
            return response
        } catch (error) {
            throw error
        }
    },
    // Получение списка групп товаров
    getGroups: async () => {
        try {
            const response = await axios.get(`${baseAPIURL}/api/v1/products/groups-list`,
                {
                    params: {
                        store_id: localStorage.getItem("current_store_id"),
                        token: localStorage.getItem("_signiture_for_store_data"),
                    },
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access_token"),
                    },
                }
            )
            return response
        } catch (error) {
            throw error
        }
    },
    // Создание группы товаров
    createGroup: async (data) => {
        try {
            const response = await axios.post(`${baseAPIURL}/api/v1/products/groups/new/`, { ...data, token: localStorage.getItem("_signiture_for_store_data") },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access_token"),
                    },
                });
            return response;
        } catch (error) {
            throw error;
        }
    },
    // Данные группы
    viewGroup: async (groupID) => {
        try {
            const response = await axios.get(`${baseAPIURL}/api/v1/products/groups/${groupID}/`,
                {
                    params: {
                        store_id: localStorage.getItem("current_store_id"),
                        token: localStorage.getItem("_signiture_for_store_data"),
                    },
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access_token"),
                    },
                }
            )
            return response
        } catch (error) {
            throw error
        }
    },
    // Данные группы
    updateGroupLabel: async (groupID, data) => {
        try {
            const response = await axios.put(`${baseAPIURL}/api/v1/products/groups/${groupID}/`, {
                ...data,
                store: localStorage.getItem("current_store_id"),
                token: localStorage.getItem("_signiture_for_store_data"),
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access_token"),
                    },
                }
            )
            return response
        } catch (error) {
            throw error
        }
    },
    // Удалить группу
    deleteGroup: async (groupID) => {
        try {
            const response = await axios.delete(`${baseAPIURL}/api/v1/products/groups/${groupID}/`,
                {
                    params: {
                        store_id: localStorage.getItem("current_store_id"),
                        token: localStorage.getItem("_signiture_for_store_data"),
                    },
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access_token"),
                    },
                }
            )
            return response
        } catch (error) {
            throw error
        }
    },
    // Добавление товаров в группу
    addProductsToGroup: async (groupID, data) => {
        try {
            const response = await axios.post(`${baseAPIURL}/api/v1/products/groups/${groupID}/add-products/`, { ...data, token: localStorage.getItem("_signiture_for_store_data"), store: localStorage.getItem("current_store_id") },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access_token"),
                    },
                });
            return response;
        } catch (error) {
            throw error;
        }
    },
    // Функция для удаления товаров из группы
    removeProductsFromGroup: async (groupID, productIds) => {
        try {
            const response = await axios.post(
                `${baseAPIURL}/api/v1/products/groups/${groupID}/remove-products/`,
                {
                    products: productIds,
                    token: localStorage.getItem("_signiture_for_store_data"),
                    store: localStorage.getItem("current_store_id")
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    },
                }
            );
            return response;
        } catch (error) {
            throw error;
        }
    },
    // Получение стилей лэйблов
    getLabels: async () => {
        try {
            const response = await axios.get(`${baseAPIURL}/api/v1/products/labels/styles/`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access_token"),
                    },
                }
            )
            return response
        } catch (error) {
            throw error
        }
    },
};

export default api;