import React from "react";
import styles from './MultipleButtonField.module.css';

function MultipleButtonField({ config, onChange, currentValue }) {

    const handleButtonClick = (configItem) => {
        onChange(configItem.value);
    }

    return (
        <div className={styles.wrapper}>
            {config.length && config.length > 0 && config.map(item => {
                return (<button id={`button_${item.id}`} key={`button_${item.id}`} type="button" onClick={() => handleButtonClick(item)} className={`${currentValue !== item.value ? styles.buttonSecondary : styles.buttonPrimary}`}>
                    {item.text}
                </button>)
            })}
        </div>
    )
}

export default MultipleButtonField;