import { useEffect, useState } from "react";
import api from "../../../../api/api";
import DropdownForForm from "../../../../components/DropDownForFormComponent";
import MultipleButtonField from "../../../../components/multipleButtonField/MultipleButtonField";
import { aiAPI } from "../../api/api";
import styles from './BaseInfoForm.module.css';
import { multipleButtonConfig } from './constants';

export const BaseInfoForm = ({ productData, handleProductDataFieldChange, productId, categories, formErrors, setFormErrors }) => {

    const [catOptions, setCatOptions] = useState([]);
    const [subcatOptions, setSubcatOptions] = useState([]);
    const [isGenerating, setIsGenerating] = useState(false);

    const handleChange = (fieldName, value) => {
        if (fieldName === 'base_combo.price') {
            handleProductDataFieldChange('base_combo.total_price', value);
            handleProductDataFieldChange(fieldName, value);
        } else if (fieldName === 'base_combo.total_price') {
            if (productData.base_combo.price < value) {
                return;
            }
            handleProductDataFieldChange(fieldName, value);
        } else {
            handleProductDataFieldChange(fieldName, value);
        }

        const updatedErrors = { ...formErrors };
        delete updatedErrors[fieldName.split(".")[1]];
        setFormErrors(updatedErrors);
    };

    const handleChangeCategory = (value) => {
        handleChange('category', value);
        api.getSubcategoriesList(value)
            .then((response) => {
                setSubcatOptions(prev => response.data);
                handleChange('subcategory', response.data[0].id);
            });
    }

    useEffect(() => {
        if (categories.length > 0) {
            setCatOptions(categories);
            if (productId === 'new') {
                handleChange('category', categories[0].id);
                api.getSubcategoriesList(categories[0].id).then((response) => {
                    setSubcatOptions(prev => response.data);
                    handleChange('subcategory', response.data[0].id);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories]);

    useEffect(() => {
        if (productData.category) {
            api.getSubcategoriesList(productData.category).then((response) => {
                setSubcatOptions(prev => response.data);
            });
        }
    }, [productData.category]);

    const generateDescription = () => {
        const productName = productData.base_combo.name;
        if (productName.length > 3) {
            setIsGenerating(true);
            aiAPI.generateDescription(productName).then(r => {
                var newDescription = r.data.result;
                const startIndex = newDescription.indexOf('Вот один из возможных вариантов описания');
                if (startIndex !== -1) {
                    newDescription = newDescription.split(':').slice(1).join(':');
                }

                handleChange('base_combo.description', newDescription.replaceAll('*', '').trim());
                setIsGenerating(false);
            });
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                ОСНОВНАЯ ИНФОРМАЦИЯ О ТОВАРЕ
            </div>
            <form className={styles.baseInfoForm}>
                <div className={styles.fieldsColumn}>
                    <div className={styles.formGroup}>
                        <span>Наименование</span>
                        <input
                            styles={formErrors.name && styles.inputError}
                            type="text"
                            name="base_combo.name"
                            value={productData.base_combo.name}
                            placeholder="Введите название товара"
                            onChange={({ target }) => handleChange(target.name, target.value)}
                            required
                        />
                        {formErrors.name && <span className={styles.fieldError}>{formErrors?.name}</span>}
                    </div>
                    <div className={styles.formGroup}>
                        <span>Категория</span>
                        <DropdownForForm
                            options={catOptions}
                            placeHolder="Выберите категорию товара"
                            initialChoice={productData.category}
                            setChoice={(value) => handleChangeCategory(value)}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <span>Подкатегория</span>
                        <DropdownForForm
                            options={subcatOptions}
                            placeHolder="Выберите подкатегорию товара"
                            initialChoice={productData.subcategory}
                            setChoice={(value) => handleChange('subcategory', value)}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <span>Артикул</span>
                        <input
                            type="text"
                            name="base_combo.vendor_code"
                            value={productData.base_combo.vendor_code}
                            placeholder="Введите название товара"
                            onChange={({ target }) => handleChange(target.name, target.value)}
                        />
                        {formErrors.vendor_code && <span className={styles.fieldError}>{formErrors?.vendor_code}</span>}
                    </div>
                </div>
                <div className={styles.fieldsColumn}>
                    <div className={styles.textArea}>
                        <div className={styles.descriptionLabels}>
                            <span>Описание товара</span>
                            {productData.base_combo.name.length > 3 && (isGenerating ? <div className={styles.loader}></div> : <span className={styles.generateBtn} onClick={generateDescription}>Сгенерировать</span>)}
                        </div>
                        <textarea
                            name="base_combo.description"
                            placeholder="Введите описание товара"
                            required
                            value={productData.base_combo.description}
                            onChange={({ target }) => handleChange(target.name, target.value)}
                        />
                        {formErrors.description && <span className={styles.fieldError}>{formErrors?.description}</span>}
                    </div>
                </div>
                <div className={styles.fieldsColumn}>
                    <div className={styles.formGroup}>
                        <span>Цена без скидки</span>
                        <input
                            type="text"
                            name="base_combo.price"
                            placeholder="Введите начальную стоимость"
                            required
                            value={productData.base_combo.price}
                            onChange={({ target }) => handleChange(target.name, target.value)}
                        />
                        {formErrors.price && <span className={styles.fieldError}>{formErrors?.price}</span>}
                    </div>
                    <div className={styles.formGroup}>
                        <span>Цена со скидкой(если есть)</span>
                        <input
                            type="text"
                            name="base_combo.total_price"
                            placeholder="Введите итоговую стоимость"
                            required
                            value={productData.base_combo.total_price}
                            onChange={({ target }) => handleChange(target.name, target.value)}
                        />
                        {formErrors.total_price && <span className={styles.fieldError}>{formErrors?.total_price}</span>}
                        <span className={styles.saleText}>Размер скидки:
                            {/* {Number(productData.base_combo.price) > 0 & Number(productData.base_combo.total_price) > 0 && <span>{Number((1 - productData?.base_combo?.total_price / productData?.base_combo?.price) * 100).toFixed(0)}%</span>} */}
                            <span>{productData?.base_combo?.price ? Number((1 - productData?.base_combo?.total_price / productData?.base_combo?.price) * 100).toFixed(0) : 0}%</span>
                        </span>
                    </div>
                    <p className={styles.emergancyText}>
                        <span className={styles.emergancy}>Внимание!</span> Управление ценой для отдельной карточки товара осуществляется в блоке с варициями
                    </p>
                    <div className={styles.formGroup}>
                        <span>Размер НДС</span>
                        <MultipleButtonField
                            config={multipleButtonConfig}
                            currentValue={productData.tax}
                            onChange={(value) => handleChange('tax', value)}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}