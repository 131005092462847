import moment from "moment";
import React, { useState } from "react";
import CustomCheckBox from "../../../components/CustomCheckBoxComponent";
import styles from "./TableComponent.module.css";

const Table = ({ usersData, setUsersData, handlerSortByDate, handlerSortByName }) => {
    const [selectAll, setSelectAll] = useState(false);
    const updateUserSelected = (userId, newSelectedValue) => {
        const updatedItems = usersData.map(item => {
            if (item.user_id === userId) {
                return { ...item, selected: newSelectedValue };
            }
            return item;
        });
        setUsersData(updatedItems);
    };

    const handleCheckboxChange = (e, id) => {
        if (e.target.checked) {
            updateUserSelected(id, true);
        } else {
            updateUserSelected(id, false);
        }
    };

    const handleSelectAll = () => {
        if (!selectAll) {
            const updatedItems = usersData.map(item => {
                return { ...item, selected: true };
            });
            setUsersData(updatedItems);
            setSelectAll(true);
        } else {
            const updatedItems = usersData.map(item => {
                return { ...item, selected: false };
            });
            setUsersData(updatedItems);
            setSelectAll(false);
        }
    };

    const handleClearSelection = () => {
        const updatedItems = usersData.map(item => {
            return { ...item, selected: false };
        });
        setUsersData(updatedItems);
        setSelectAll(false);
    };

    return (
        <div className={styles.container}>
            <table className={styles.table}>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                    <thead>
                        <tr>
                            <th style={{ padding: "10px", position: "relative" }}>Имя пользователя <button className={styles.sortBtn} onClick={handlerSortByName}></button></th>
                            <th style={{ padding: "10px", position: "relative" }}>
                                Последний визит
                                <button className={styles.sortBtn} onClick={handlerSortByDate}></button>
                            </th>
                            <th style={{ padding: "10px" }}>Товаров в корзине</th>
                        </tr>
                    </thead>
                </table>
                <div className={styles.userList}>
                    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                        <tbody>
                            {usersData.map((item) => (
                                <tr key={item.user_id} className={styles.item}>
                                    <td className={styles.itemUsername}>
                                        <CustomCheckBox
                                            checked={item.selected}
                                            onChange={(e) => handleCheckboxChange(e, item.user_id)}
                                        />
                                        <span>{item.username === '' || item.username === undefined ? 'отсутствует' : item.username.length >= 17 ? item.username.slice(0, 17) + "..." : item.username}</span>
                                    </td>
                                    <td>{moment(item.updated_at).format('HH:mm DD.MM.YYYY')}</td>
                                    <td>{item.cart_items_count}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </table>
            <div className={styles.buttons}>
                <button onClick={handleSelectAll}>{selectAll ? "Снять выбор" : "Выбрать все"}</button>
                <button onClick={handleClearSelection}>Очистить выбор</button>
            </div>
        </div>
    );
};

export default Table;
