import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function RefCodeServicePage() {
    const { code } = useParams();
    useEffect(() => {
        localStorage.setItem('__invite_code', code);
        window.location.href = 'https://thebotique.ru/';
    }, []);

    return (<></>);
}