import React from "react";
import Notification from "./Notification";

export default function NotificationsManager({ setNotify }) {
    let [notifications, setNotifications] = React.useState([]);

    let createNotification = ({ type, children }) => {
        setNotifications((prevNotifications) => {
            return [
                ...prevNotifications,
                {
                    children,
                    type,
                    id: prevNotifications.length,
                },
            ];
        });
    };

    React.useEffect(() => {
        setNotify(({ type, children }) =>
            createNotification({ type, children })
        );
    }, [setNotify]);

    let deleteNotification = (id) => {
        const filteredNotifications = notifications.filter(
            (_, index) => id !== index,
            []
        );
        setNotifications(filteredNotifications);
    };

    return notifications.map(({ id, ...props }, index) => (
        <Notification
            key={id}
            animated={index === notifications.length - 1 && index !== 0}
            handleClose={() => deleteNotification(index)}
            {...props}
        />
    ));
}
