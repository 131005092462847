import React from 'react';
import InputMask from 'react-input-mask';
import styles from './RegistrationPage.module.css';

function PhoneInput({ formData, handleChange }) {
    return (
        <InputMask
            mask="+7 (999) 999-99-99"
            value={formData.tel}
            onChange={handleChange}
        >
            {() => (
                <input
                    required
                    type="phone"
                    name="tel"
                    className={styles.formInput}
                />
            )}
        </InputMask>
    );
}

export default PhoneInput;
