import React, { useRef } from "react";
import { default as statPlus } from "../../../../../../assets/icon add-3.png";
import ImageComponent from '../../../../../../components/ImageCustomComponet';
import { error } from "../../../../../../components/notification";
import styles from './PhotoListItem.module.css';

export const PhotoListItem = ({ url, width, height, index, deleteCallback, chooseFilesCallback }) => {
    const fileInputRef = useRef(null);

    const handleImageLoad = (e) => {
        const maxFileSize = 3 * 1024 * 1024;

        chooseFilesCallback(Array.from(e.target.files).map((file) => {
            if (file.size > maxFileSize) {
                error('Размер фото не должен превышать 3 Мб');
            }
            return { "url": URL.createObjectURL(file), "file": file };
        }));
    };

    return (
        <div className={styles.wrapper} style={{ width: width }}>
            {url ?
                <ImageComponent imagePath={url} ourWidth={width} height={height} /> :
                <div className={styles.emptyItemWrapper} style={{ width, height }} htmlFor="addImageInput" onClick={() => fileInputRef?.current.click()}>
                    <img src={statPlus} alt="" />
                    {/* <span>Добавить фотографию</span> */}
                </div>
            }
            <input
                ref={fileInputRef}
                type="file"
                id="addImageInput"
                style={{ display: "none" }}
                multiple
                onChange={handleImageLoad}
                accept="image/png, image/jpeg"
            />
            {url === null ? null : <button type="button" title="Удалить" onClick={() => deleteCallback(index)} />}
        </div>
    );
}