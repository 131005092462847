import React from "react";
import { Link } from "react-router-dom";
import plane from "../assets/plane.svg";
import styles from "../styles/FooterComponent_v2.module.css";

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerCol}>
        <div className={styles.footerRow}>
          <span>Botique</span>
          <span>2023-{new Date().getFullYear()} © Все права защищены</span>
        </div>
        <div className={styles.footerRow}>
          <Link
            to={"https://docs.thebotique.ru/license-agreement"}
            target="_blank"
          >
            Лицензионный договор
          </Link>
          <Link to={"https://docs.thebotique.ru/terms-of-use"} target="_blank">
            Правила использования cервиса
          </Link>
          <Link
            to={"https://docs.thebotique.ru/privacy-policy"}
            target="_blank"
          >
            Политика обработки персональных данных
          </Link>
        </div>
      </div>
      <div>
        <div className={styles.partnerText}>
          Наш партнер
          <div className={styles.partnerLogo} />
        </div>
      </div>
      <div className={styles.footerCol}>
        <div className={styles.footerRow}>
          <Link to={"mailto:thebotique@mail.ru"} target="_blank" className={styles.footerLink}>
            @ Напишите нам
          </Link>
          <Link to={"https://t.me/BotiqueSupport_bot?start"} target="_blank" className={styles.footerLink}>
            <img src={plane} alt="" /> Быстрая поддержка
          </Link>
          <Link to={"https://t.me/thebotique_ru"} target="_blank" className={styles.footerLink}>
            <img src={plane} alt="" /> Наши новости
          </Link>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
