import React from 'react';
import Footer from '../../components/FooterComponent_v2';
import Header from '../../components/HeaderComponent_v2';
import Help from './components/HelpComponent_v2';

const SupportPage = () => {

    return (
        <div>
            <Header title={"Поддержка"} />
            <Help />
            <Footer />
        </div>
    );
};

export default SupportPage;
