import styles from "../styles/CustomInputComponent_v2.module.css";
import React, { useState } from 'react';

const CustomFileInput = ({ onSelect, setPreviewUrl }) => {
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
      onSelect(file);
    }
  };

  return (
    <div>
      <label className={styles.text} htmlFor="fileInput">Загрузить логотип</label>
      <input
        type="file"
        accept="image/*"
        id="fileInput"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      {fileName && <p>Выбранный файл: {fileName}</p>}
    </div>
  );
};

export default CustomFileInput;

