import React, { useEffect, useState } from "react";
import api from "../../../api/api";
import copy from "../../../assets/icon-copy.png";
import NumberFormatter from "../../../components/FormatNumbersComponent";
import { error, success } from "../../../components/notification";
import styles from "./ReferalInfoComponent.module.css";

function ReferalInfoComponent({ referral }) {
    const [refCode, setRefCode] = useState(null);
    const [wallet, setWallet] = useState(0);
    const [showTooltip, setShowTooltip] = useState(false);
    const [popUpInfo, setPopUpInfo] = useState('Скопируйте ссылку для интеграции магазина в телеграмм канал');

    useEffect(() => {
        setRefCode(referral?.code);
        setWallet(referral?.wallet);
    }, [referral]);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`https://admin.thebotique.ru/invite/${referral?.code}`);
        success('Ссылка скопирована в буфер');
    };

    const generateCode = () => {
        if (refCode === null) {
            api.generateReferralCode().then((r) => {
                if (r.status) {
                    setRefCode(r?.data?.code);
                    success('Пригласительный код сгенерирован');
                } else {
                    error('Произошла ошибка, попробуйте позже');
                }
            });
        } else {
            navigator.clipboard.writeText(refCode);
        }
    };
    return (
        <div className={styles.container}>
            <div className={styles.containerRaw}>
                <span>Ваш пригласительный код:</span>
                <span className={styles.linkStyle} onClick={generateCode}>{refCode === null ? 'Получить' : refCode}</span>
                {refCode !== null && <span> Копировать ссылку:</span>}
                {refCode !== null && <img className={styles.copyLink} src={copy} alt="" onClick={handleCopyLink} />}
            </div>
            {refCode !== null && wallet !== 0 && <div className={styles.containerRaw}>
                <span>Ваш баланс:</span>
                <div>
                    <NumberFormatter number={wallet / 100} />
                    <span>₽</span>
                </div>
            </div>}
        </div >
    )
}
export default ReferalInfoComponent;