import React from "react";
import styles from "../styles/PopupCustomComponent.module.css";
import Popup from "reactjs-popup";
function PopupCustomComponent({title,text,handler,paramsHandler,triggerEl}) {
    const contentStyle = { width: window.innerWidth >= 701 ? 380:300, padding: "40px 60px", borderRadius: 16 };
    return (
        <Popup trigger={triggerEl} modal {...{ contentStyle }}>
            {close => (
                    <div  className={styles.content}>
                        <span>{title}</span>
                        <span>{text}</span>
                        <div className={styles.btns}>
                            <button className={styles.popupBtnBack} onClick={close}>Отменить</button>
                            <button className={styles.popupBtnDel} onClick={(e) => { close(); handler(paramsHandler === undefined ? e:paramsHandler); }}>Подтвердить</button>
                        </div>
                        <button className={styles.popupClose} onClick={close}></button>
                    </div>
                )}
        </Popup>
    )
}
export default PopupCustomComponent;