import axios from "axios";
import api from "../api/api";

let refresh = false;

axios.interceptors.response.use(resp => resp, async error => {
    if (error.response) {
        
        const isExternalApiTelegram = error.response.config.url.startsWith('https://api.telegram.org');
        const isExternalApiBoxberry = error.response.config.url.startsWith('https://api.boxberry.ru');

        if (error.response.status === 401 && !isExternalApiTelegram && !isExternalApiBoxberry && !refresh && window.location.pathname !== '/login') {
            refresh = true;
            try {
                const response = await api.getRefresh();

                if (response.code === "ERR_BAD_REQUEST") {
                    localStorage.clear();

                    if (window.location.pathname !== '/login') {
                        window.location.reload();
                    }
                } else {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data['access']}`;
                    localStorage.setItem('access_token', response.data.access);
                    localStorage.setItem('refresh_token', response.data.refresh);
                    refresh = false;
                    window.location.reload();
                    return axios(error.config);
                }
            } catch (error) {
                console.log(error);
            } finally {
                refresh = false;
            }

            // api.getRefresh()
            //     .then((response) => {
            //         if (response.code === "ERR_BAD_REQUEST") {
            //             localStorage.clear();

            //             if (window.location.pathname !== '/login') {
            //                 window.location.reload();
            //             }
            //         }
            //         else {
            //             axios.defaults.headers.common['Authorization'] = `Bearer ${response.data['access']}`;
            //             localStorage.setItem('access_token', response.data.access);
            //             localStorage.setItem('refresh_token', response.data.refresh);
            //             window.location.reload();
            //             return axios(error.config);
            //         }
            //     })
            //     .catch((error) => { console.log(error) });
        }
        // refresh = false;
        return error;
    }
});