import ReactDOM from 'react-dom';
import NotificationsManager from './NotificatinManager';
import Notification, { NotificationType } from './Notification';
import styles from './Notification.module.css';

function createContainer() {
    const portalId = "notificationContainer";
    let element = document.getElementById(portalId);

    if (element) {
        return element;
    }

    element = document.createElement("div");
    element.setAttribute("id", portalId);
    element.className = styles.container;
    document.body.appendChild(element);
    return element;
}

const containerElement = createContainer();
let notify;

ReactDOM.render(
    <NotificationsManager
        setNotify={(notifyFn) => {
            notify = notifyFn;
        }}
    />,
    containerElement
);

export { Notification, NotificationType };

export function info(children) {
    return notify({
        type: NotificationType.info,
        children,
    });
}

export function success(children) {
    return notify({
        type: NotificationType.success,
        children,
    });
}

export function warning(children) {
    return notify({
        type: NotificationType.warning,
        children,
    });
}

export function error(children) {
    return notify({
        type: NotificationType.error,
        children,
    });
}