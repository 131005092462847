import React, { useEffect, useState } from 'react';
import api from '../../api/api';
import Footer from '../../components/FooterComponent_v2';
import Header from '../../components/HeaderComponent_v2';
import NotificationComponent from '../../components/NotificationComponent';
import styles from "./OrdersPage.module.css";
import OrderListComponent from './components/OrderListComponent';

function OrdersPage() {
    const [notificationText, setNotificationText] = useState('');
    const [notificationState, setNotificationState] = useState(false);

    const [stats, setStats] = useState({ count: 0, buyers: 0, check: 0, sum: 0 });

    useEffect(() => {
        const storeID = localStorage.getItem('current_store_id');
        if (storeID) {
            api.getOrdersStatistics(storeID).then(r => {
                if (r.status === 200) {
                    const orderStatistics = r.data[0];

                    const mergedResult = {
                        count: Number(orderStatistics.total_orders).toFixed(0),
                        sum: Number(orderStatistics.order_amount / 100).toFixed(0),
                        buyers: Number(orderStatistics.total_customers).toFixed(0),
                        check: Number(orderStatistics.average_check / 100).toFixed(0),
                    };
                    setStats((prevStats) => (mergedResult));
                }
            });

        } else {
            setNotificationState(true);
            setNotificationText("Выберите магазин для отображения списка заказов");
        }
    }, []);

    useEffect(() => {
        if (notificationState) {
            const timer = setTimeout(() => {
                setNotificationState(false);
                setNotificationText("");
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [notificationState]);

    return (
        <div>
            <Header title={"Страница заказов"} />
            {notificationState ? <NotificationComponent text={notificationText} /> : null}
            <div className={styles.megaContainer}>
                <OrderListComponent stats={stats} />
            </div>
            <Footer />
        </div>
    );
}

export default OrdersPage;
