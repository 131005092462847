import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import blueOne from "../../../assets/icon add-2.png";
import plus from "../../../assets/icon add.png";
import ShopItem from "./ShopListItemComponent_v2";
import styles from "./ShopsComponent_v2.module.css";

function ShopList({ shops, deleteHandler, viewHandler, tariff }) {
    const [plusImage, setPlusImage] = useState(plus);
    const nav = useNavigate();
    const handlerForPlus = () => {
        setPlusImage(blueOne);
    };

    const handlerForPlusBack = () => {
        setPlusImage(plus);
    };

    const handlerForAddShop = () => {
        if (tariff.design_choice) {
            nav('/store/set-design');
        } else {
            nav('/store/new');
        }
    };

    return (
        <div className={styles.shops}>
            <h1 className={styles.shopsTitle}>все ваши магазины</h1>
            <div className={styles.shopsStorage}>
                {shops.length === 0 ? null :
                    shops.map(shop => <ShopItem key={uuid()} shop={shop} deleteHandler={deleteHandler} viewHandler={viewHandler} />)
                }
                {tariff.allow_stores ?
                    <button onClick={handlerForAddShop} className={styles.shopAdd} onMouseLeave={handlerForPlusBack} onMouseEnter={handlerForPlus}>
                        <img src={plusImage} alt="plus"></img>
                        <span>Добавить новый магазин</span>
                    </button> : null}
            </div>
        </div>
    )
}

ShopList.defaultProps = {
    shops: []
}

export default ShopList;